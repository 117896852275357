import { PuffLoader } from "react-spinners";
import { FaSpinner } from "react-icons/fa";
import { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../utils/authContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/helpers";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

export default function AdminLogin() {
  const navigate = useNavigate();

  const { currentUser, signIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loginBtnClicked, setLoginBtnClicked] = useState(false);

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  }, []);

  const handleOnLogin = useCallback(
    async (e) => {
      e.preventDefault();
      if (email === process.env.REACT_APP_ADMIN_EMAIL) {
        try {
          setLoginBtnClicked(true);
          await signIn(email, password);
          // Redirect to home page after successful login
          if (
            currentUser &&
            currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL
          ) {
            toast.success("Logged in successfully!", toastOptions);
            navigate("/admin/dashboard");
          }
        } catch (error) {
          setLoginBtnClicked(false);
          const errorCode = error.code;
          //const errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            toast.error("Invalid email or password", toastOptions);
          } else {
            toast.error(errorCode, toastOptions);
          }
        }
      } else {
        toast.error("Not authorized", toastOptions);
      }
    },
    [email, password, currentUser, signIn, navigate]
  );

  useEffect(() => {
    if (
      currentUser &&
      currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL
    ) {
      navigate("/admin/dashboard");
    }
  }, [navigate, currentUser]);

  return (
    <>
      <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from 15% via-[#FAC954] via-30% to-[#1e3a8a] to-70%">
        {currentUser && (
          <div className="flex justify-center w-full ">
            <PuffLoader size={60} />
          </div>
        )}

        {!currentUser && (
          <div className="w-full max-w-sm ">
            <form
              className="px-8 py-8 mx-4 mb-4 space-y-4 bg-white rounded shadow-md sm:mx-0 bg-opacity-20"
              onSubmit={handleOnLogin}
            >
              <div className="flex items-center justify-center">
                <Link to="/">
                  <img
                    src="/assets/logo.svg"
                    alt="JK SOLAR ENERGY"
                    width={140}
                    height={140}
                    loading="eager"
                  />
                </Link>
              </div>

              <h1 className="text-xl font-bold text-center text-gray-900 ">
                Admin Login
              </h1>

              <div className="">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  onChange={handleOnChange}
                  id="email"
                  name="email"
                  className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-bold text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    onChange={handleOnChange}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
                    placeholder="Enter your password"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pb-4 pr-4 text-gray-500"
                  >
                    {showPassword ? (
                      <BsEyeFill className="w-5 h-5" />
                    ) : (
                      <BsEyeSlashFill className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
              <div className="mb-6 text-center">
                <button
                  className="flex items-center justify-center w-full px-4 py-2 font-bold text-white bg-blue-900 rounded-full disabled hover:bg-blue-700 focus:outline-none focus:shadow-outline "
                  disabled={loginBtnClicked}
                  type="submit"
                >
                  {loginBtnClicked ? (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  ) : (
                    "Log in"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </main>
    </>
  );
}
