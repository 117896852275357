import { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { setDoc, serverTimestamp, doc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { AuthContext } from "../utils/authContext";
import { toastOptions } from "../utils/helpers";

const AddUserModal = ({ isModalOpen, setIsModalOpen }) => {
  const { signUp } = useContext(AuthContext);

  const defaultPhotoUrl =
    "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [photoUrl, setPhotoUrl] = useState(defaultPhotoUrl);

  const storage = getStorage();
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setName("");
      setEmail("");
      setPassword("");
      setConfPassword("");
      setPhotoUrl(defaultPhotoUrl);
    }
  }, [isModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password === confPassword) {
        setAddButtonClicked(true);
        const { user } = await signUp(email, password);
        const data = {
          displayName: name,
          email,
          photoUrl,
          admin: false,
          active: false,
          emailVerified: false,
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          uid: user.uid.toString(),
        };
        const docRef = doc(db, "users", user.uid.toString());
        await setDoc(docRef, data);

        toast.success("User added successfully!", toastOptions);
        setAddButtonClicked(false);
        setIsModalOpen(false);
      } else {
        toast.error("Passwords do not match!", toastOptions);
      }
    } catch (error) {
      setAddButtonClicked(false);
      toast.error(error.code, toastOptions);
      console.error(error);
    }
  };

  const handleImageUpload = (e) => {
    try {
      const file = e.target.files[0];
      setImageUploading(true);
      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
      } else {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000);
        const storageRef = ref(storage, `/users/${timestamp}-${randomNum}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => console.error(err),
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask?.snapshot?.ref);
            setPhotoUrl(downloadUrl);
            setImageUploading(false);
          }
        );
      }
    } catch (error) {
      setPhotoUrl(defaultPhotoUrl);
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isModalOpen} ariaHideApp={false}>
      <div className="p-4">
        <h1 className="mb-4 text-2xl font-bold">Add User</h1>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="name"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="password"
            >
              Password:
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="confPassword"
            >
              Confirm Password:
            </label>
            <input
              type="password"
              id="confPassword"
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="flex items-center mb-4 space-x-8">
            {photoUrl && (
              <img
                src={photoUrl}
                alt="user"
                className="w-20 mt-4 rounded-lg md:w-40 "
              />
            )}
            {!imageUploading ? (
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                  id="image-upload"
                />
                <label
                  htmlFor="image-upload"
                  className="p-2 text-white bg-blue-900 rounded cursor-pointer md:px-4 md:py-2 hover:bg-blue-700"
                >
                  Upload Image
                </label>
              </div>
            ) : (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setIsModalOpen(false)}
            type="button"
            className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600 focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-blue-900 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
          >
            {addButtonClicked && (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            )}
            {!addButtonClicked && "Add User"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
