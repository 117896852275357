import React from "react";
import { parseFirebaseTimestamp } from "../utils/helpers";

export default function UserCustomersTable({ customers }) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
          >
            Customer ID
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
          >
            Customer Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
          >
            Date
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {customers.map((customer) => (
          <tr key={customer.id}>
            <td className="px-6 py-4 text-sm font-bold text-gray-500 whitespace-nowrap">
              {customer.id}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {customer.customer_name}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {parseFirebaseTimestamp(customer.created_at)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
