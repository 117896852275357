import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-blue-900 text-white">
      <h1 className="text-4xl font-bold mb-4">404 Error</h1>
      <p className="text-xl mb-8">
        The page you're looking for could not be found.
      </p>
      <Link
        to="/"
        className="px-6 py-3 bg-white text-blue-900 font-bold rounded-full transition duration-300 hover:bg-gray-200"
      >
        Return to Home
      </Link>
    </div>
  );
}
