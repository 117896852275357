import { AuthContext } from "./authContext";
import { useState, useEffect, useCallback } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { app } from "../firebase/firebaseConfig";
import { updateUserTimestamp } from "./database";

export const AuthProvider = ({ children }) => {
  const auth = getAuth(app);

  const [currentUser, setCurrentUser] = useState(() => {
    if (typeof window !== "undefined") {
      const storedUser = sessionStorage.getItem("currentUser");
      return storedUser ? JSON.parse(storedUser) : null;
    } else {
      return null;
    }
  });

  const signIn = useCallback(
    async (email, password) => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        await updateUserTimestamp(auth.currentUser.uid);
      } catch (error) {
        throw error;
      }
    },
    [auth]
  );

  const signUp = useCallback(
    async (email, password) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
          {
            autoSignIn: false,
          }
        );
        return userCredential;
      } catch (error) {
        throw error;
      }
    },
    [auth]
  );

  const signOut = useCallback(() => {
    return auth.signOut();
  }, [auth]);

  const updateUserProfile = useCallback(
    (displayName, photoURL) => {
      return updateProfile(auth.currentUser, {
        displayName: displayName,
        photoURL: photoURL,
      });
    },
    [auth]
  );

  const sendVerificationEmail = useCallback(() => {
    return sendEmailVerification(auth.currentUser);
  }, [auth]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      try {
        if (user) {
          if (typeof window !== "undefined") {
            sessionStorage.setItem("currentUser", JSON.stringify(user));
          }
          setCurrentUser(user);
        } else {
          if (typeof window !== "undefined") {
            sessionStorage.removeItem("currentUser");
          }
          setCurrentUser(null);
        }
      } catch (error) {
        console.error(error);
      }
    });

    return () => {
      try {
        unsubscribe();
      } catch (error) {
        console.error(error);
      }
    };
  }, [auth]);

  const value = {
    currentUser,
    updateUserProfile,
    sendVerificationEmail,
    signIn,
    signUp,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
