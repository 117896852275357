import AdminLayout from "../../components/AdminLayout";
import EmployeeTable from "../../components/EmployeeTable";
import React, { useEffect, useState, useMemo } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import PuffLoader from "react-spinners/PuffLoader";
import ProtectedRoute from "../../components/ProtectedRoute";
import { BsPersonFillAdd } from "react-icons/bs";
import { Link } from "react-router-dom";
import LayoutHeader from "../../components/LayoutHeader";
import { toastOptions } from "../../utils/helpers";
import { toast } from "react-toastify";

function useEmployees() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const employeesCol = collection(db, "employee_list");
    const unsubscribe = onSnapshot(
      query(employeesCol, orderBy("employee_code", "asc")),
      (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setEmployees(docs);
        setLoading(false);
      },
      (error) => {
        if (error?.code === "resource-exhausted") {
          toast.error("Quota exceeded", toastOptions);
        } else {
          toast.error("Something went wrong", toastOptions);
        }
        setLoading(false);
      }
    );

    return unsubscribe;
  }, []);

  return [employees, loading];
}

export default function Employees() {
  const [searchQuery, setSearchQuery] = useState("");

  const [employees, loading] = useEmployees();

  const filteredEmployees = useMemo(() => {
    return employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [employees, searchQuery]);

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  return (
    <>
      <ProtectedRoute>
        <AdminLayout>
          <div className="h-full overflow-x-auto overflow-y-auto ">
            <LayoutHeader title={"Employees"} />
            <div className="mx-4 header">
              <div className="flex justify-between py-2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search by Employee name"
                  className="px-2 py-2 text-gray-600 border rounded-md w-45 sm:w-96"
                />
                <Link
                  to="/admin/add-employee"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <BsPersonFillAdd className="w-5 h-5 mr-2 " />
                  <span className="hidden md:block">Add Employee</span>
                </Link>
              </div>
            </div>
            {loading && (
              <div className="flex justify-center w-full py-8">
                <PuffLoader size={60} />
              </div>
            )}

            {!loading && <EmployeeTable employees={filteredEmployees} />}
          </div>
        </AdminLayout>
      </ProtectedRoute>
    </>
  );
}
