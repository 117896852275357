import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { orderBy } from "lodash";
import LayoutHeader from "../../components/LayoutHeader";
import { PuffLoader } from "react-spinners";
import SolarRoofTopLeadsTable from "../../components/SolarRoofTopLeadsTable";
import { TbRefresh } from "react-icons/tb";

const SolarRoofTop = () => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [isCached, setIsCached] = useState(false);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredLeads = useMemo(
    () =>
      leads.filter(
        (lead) =>
          lead.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          lead.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (lead.phone && lead.phone.includes(searchQuery))
      ),
    [leads, searchQuery]
  );

  const fetchSolarRoofTopLeads = async () => {
    setIsLoading(true);

    const solarRoofTopLeadsQuery = query(
      collection(db, "solar_roof_top_leads"),
      orderBy("timestamp", "desc")
      //limit(10)
    );

    const docs = [];
    const querySnapshot = await getDocs(solarRoofTopLeadsQuery);
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });

    docs.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

    setLeads(docs);
    setTotalLeads(docs.length);

    setIsLoading(false);
  };

  const handleRefreshData = async () => {
    setIsCached(true);
    await fetchSolarRoofTopLeads();
    setIsCached(false);
  };

  useEffect(() => {
    fetchSolarRoofTopLeads();
  }, []);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-x-auto overflow-y-auto ">
          <LayoutHeader title={"Solar Roof Top Leads"} />
          <div className="mx-4 header">
            <div className="items-center justify-between py-2 space-y-2 md:space-y-0 md:flex">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Name or Phone or Address"
                className="w-full px-2 py-2 text-gray-600 border rounded-md md:w-45 sm:w-96"
              />
              <div className="flex flex-row items-center mr-10 space-x-4">
                <p className="text-sm font-semibold text-gray-600">
                  Total Leads:{" "}
                  <span className="text-lg font-bold">{totalLeads}</span>
                </p>
                <button
                  onClick={handleRefreshData}
                  disabled={isCached}
                  className="px-2 py-2 bg-blue-900 rounded-md hover:bg-blue-950"
                >
                  <div className="flex items-center space-x-2 text-white ">
                    <TbRefresh />
                  </div>
                </button>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}

          {!isLoading && <SolarRoofTopLeadsTable leads={filteredLeads} />}
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
};

export default SolarRoofTop;
