import React, { useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/authContext";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;

export default function LoginPage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const handleAdminLogin = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/admin");
    },
    [navigate]
  );

  const handleUserLogin = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/user");
    },
    [navigate]
  );

  useEffect(() => {
    if (currentUser) {
      const { email } = currentUser;
      if (email === ADMIN_EMAIL) {
        navigate("/admin/dashboard");
      } else {
        navigate("/user/dashboard");
      }
    }
  }, [currentUser, navigate]);

  return (
    <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from 15% via-[#FAC954] via-30% to-[#1e3a8a] to-70%">
      <div className="w-full max-w-sm">
        <div className="flex items-center justify-center">
          <img
            src="/assets/logo.svg"
            alt="JK SOLAR ENERGY"
            width={140}
            height={140}
            loading="eager"
          />
        </div>

        <div className="flex flex-col items-center justify-between py-10 mx-8 md:space-x-8 md:flex-row">
          <button
            onClick={handleAdminLogin}
            className="w-full px-4 py-2 mb-4 font-bold text-white bg-blue-900 rounded-md md:w-1/2 hover:bg-blue-700 focus:outline-none focus:shadow-outline md:mb-0"
          >
            Admin Login
          </button>

          <button
            onClick={handleUserLogin}
            className="w-full px-4 py-2 font-bold text-white bg-blue-900 rounded-md md:w-1/2 hover:bg-blue-700 focus:outline-none focus:shadow-outline"
          >
            User Login
          </button>
        </div>
      </div>
    </main>
  );
}
