import { PuffLoader } from "react-spinners";
import { FaSpinner } from "react-icons/fa";
import { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../utils/authContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/helpers";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

export default function UserLogin() {
  const navigate = useNavigate();
  const { currentUser, signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loginBtnClicked, setLoginBtnClicked] = useState(false);

  const handleOnChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  }, []);

  const checkUserActivity = async (email) => {
    try {
      const collRef = collection(db, "users");
      const q = query(collRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      const active = querySnapshot.docs[0].data().active;
      return active;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleOnLogin = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (loginBtnClicked) return; // Prevent multiple clicks
        setLoginBtnClicked(true);

        // check if user is active then login
        const active = await checkUserActivity(email);
        if (!active) {
          toast.error(
            "Your account is not active. Please contact the admin.",
            toastOptions
          );
        } else {
          await signIn(email, password);
          // Redirect to home page after successful login
          toast.success("Logged in successfully!", toastOptions);
          navigate("/user/dashboard");
        }
      } catch (error) {
        const errorCode = error.code;
        if (errorCode === "auth/wrong-password") {
          toast.error("Invalid email or password", toastOptions);
        } else {
          toast.error(errorCode, toastOptions);
        }
      } finally {
        setLoginBtnClicked(false);
      }
    },
    [email, password, signIn, navigate, loginBtnClicked]
  );

  useEffect(() => {
    if (currentUser) {
      navigate("/user/dashboard");
    }
  }, [currentUser, navigate]);

  return (
    <main className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#faa93b] from 15% via-[#FAC954] via-30% to-[#1e3a8a] to-70%">
      {currentUser ? (
        <div className="flex justify-center w-full">
          <PuffLoader size={60} />
        </div>
      ) : (
        <div className="w-full max-w-sm">
          <form
            className="px-8 py-8 mx-4 mb-4 space-y-4 bg-white rounded shadow-md sm:mx-0 bg-opacity-20"
            onSubmit={handleOnLogin}
          >
            <div className="flex items-center justify-center">
              <Link to="/">
                <img
                  src="/assets/logo.svg"
                  alt="JK SOLAR ENERGY"
                  width={140}
                  height={140}
                  loading="eager"
                />
              </Link>
            </div>

            <h1 className="text-xl font-bold text-center text-gray-900">
              User Login
            </h1>

            <div className="">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-bold text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                onChange={handleOnChange}
                id="email"
                name="email"
                className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-bold text-gray-700"
              >
                Password
              </label>
              <div className="relative">
                <input
                  onChange={handleOnChange}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pb-4 pr-4 text-gray-500"
                >
                  {showPassword ? (
                    <BsEyeFill className="w-5 h-5" />
                  ) : (
                    <BsEyeSlashFill className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>
            <div className="mb-6 text-center">
              <button
                className="flex items-center justify-center w-full px-4 py-2 font-bold text-white bg-blue-900 rounded-full disabled hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                disabled={loginBtnClicked}
                type="submit"
              >
                {loginBtnClicked ? (
                  <FaSpinner className="mx-2 animate-spin" size={20} />
                ) : (
                  "Log in"
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </main>
  );
}
