import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/authContext";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";

export default function UserProtectedRoute({ children }) {
  const navigate = useNavigate();
  const { currentUser, signOut } = useContext(AuthContext);

  const addUserDetailsIfNotAdded = async () => {
    // add user to users collection in firestore if not already added
    const docRef = doc(db, "users", `${currentUser?.uid}`);
    const data = {
      active: true,
      admin: false,
      email: currentUser?.email,
      emailVerified: currentUser?.emailVerified,
      displayName: currentUser?.displayName,
      photoURL: currentUser?.photoURL,
      createdAt: currentUser?.metadata?.creationTime,
      lastLogin: currentUser?.metadata?.lastSignInTime,
      uid: currentUser?.uid,
    };
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      await setDoc(docRef, data);
    }
  };

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser?.uid), (doc) => {
      const active = doc.data().active;
      if (!active) {
        toast.error(
          "Your account has been deactivated. Please contact the admin.",
          toastOptions
        );
        signOut();
      }
    });
    return unsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL) {
      navigate("/admin");
    } else if (currentUser) {
      //TODO: check if user is active
      // if user is not active, sign out

      // add User to users collection in firestore if not already added
      addUserDetailsIfNotAdded();
    } else {
      navigate("/user");
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return currentUser?.email !== process.env.REACT_APP_ADMIN_EMAIL ? (
    <div>{children}</div>
  ) : null;
}
