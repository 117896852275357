import React, { useState, useEffect, useCallback, useMemo } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { Link } from "react-router-dom";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import OrdersTable from "../../components/OrdersTable";
import { PuffLoader } from "react-spinners";
import LayoutHeader from "../../components/LayoutHeader";
import useOrdersStore from "../../utils/useOrdersStore";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPmsgyOnly, setIsPmsgyOnly] = useState(false);

  const { docs, fetchOrders, loading } = useOrdersStore();

  const updateOrders = useCallback((newOrders) => {
    setOrders(newOrders);
  }, []);

  useEffect(() => {
    const getOrders = async () => {
      await fetchOrders();
      updateOrders(docs);
    };

    getOrders();
  }, [docs, fetchOrders, updateOrders]);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handlePmsgyToggle = () => {
    setIsPmsgyOnly((prevState) => !prevState);
  };

  const filteredOrders = useMemo(() => {
    return orders
      .filter((order) => {
        const query = searchQuery.toLowerCase();
        return (
          order.customer_name.toLowerCase().includes(query) ||
          (order.customer_phone_no &&
            order.customer_phone_no.toLowerCase().includes(query)) ||
          (order.customer_address &&
            order.customer_address.toLowerCase().includes(query))
        );
      })
      .filter((order) => {
        if (isPmsgyOnly) {
          return order.pmsgyCustomer === "yes";
        }
        return true; // No filter if checkbox is not checked
      });
  }, [orders, searchQuery, isPmsgyOnly]);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-y-auto">
          <LayoutHeader title={"Orders"} />

          <div className="mx-4 header">
            <div className="flex flex-col justify-between py-2 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
              <div className="flex items-center flex-grow">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search by Customer's name or Phone number or Address"
                  className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md w-full sm:w-[30rem] focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* "PMSGY Only" Checkbox */}
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="pmsgy-checkbox"
                  checked={isPmsgyOnly}
                  onChange={handlePmsgyToggle}
                  className="w-5 h-5 text-blue-900 rounded"
                />
                <label
                  htmlFor="pmsgy-checkbox"
                  className="font-medium text-gray-600"
                >
                  PMSGY Only
                </label>
              </div>

              <div className="btn">
                <Link
                  to="/admin/add-order"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <MdOutlineAddShoppingCart className="w-5 h-5 mr-2" />
                  <span className="hidden md:block">Add Order</span>
                </Link>
              </div>
            </div>
          </div>

          {loading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}

          <div className="overflow-x-auto">
            {!loading && <OrdersTable orders={filteredOrders} />}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
