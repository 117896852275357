import AdminLayout from "../../components/AdminLayout";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import ProtectedRoute from "../../components/ProtectedRoute";
import { PuffLoader } from "react-spinners";
import DashboardCard from "../../components/DashboardCard";
import LayoutHeader from "../../components/LayoutHeader";
import { FcSalesPerformance } from "react-icons/fc";
import { GiShoppingBag } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { formatAmountToInr } from "../../utils/helpers";
import Overview from "../../components/Overview";
import useOrdersStore from "../../utils/useOrdersStore";
import useCustomersStore from "../../utils/useCustomersStore";

const initialGraphData = [
  { name: "Jan", total: 0 },
  { name: "Feb", total: 0 },
  { name: "Mar", total: 0 },
  { name: "Apr", total: 0 },
  { name: "May", total: 0 },
  { name: "Jun", total: 0 },
  { name: "Jul", total: 0 },
  { name: "Aug", total: 0 },
  { name: "Sep", total: 0 },
  { name: "Oct", total: 0 },
  { name: "Nov", total: 0 },
  { name: "Dec", total: 0 },
];

export default function Dashboard() {
  const { querySnapshot, fetchOrders, loading } = useOrdersStore();
  const { fetchCustomers } = useCustomersStore();

  const [firmName, setFirmName] = useState("all_firms");
  const [financePartner, setFinancePartner] = useState("all_partners");
  const [year, setYear] = useState(new Date().getFullYear());

  const [orderSnapshot, setOrderSnapshot] = useState(null);

  const [numCustomers, setNumCustomers] = useState(0);
  const [numOrders, setNumOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalSalesThisMonth, setTotalSalesThisMonth] = useState(0);
  const [totalCommissionYear, setTotalCommissionYear] = useState(0);
  const [totalDownpaymentYear, setTotalDownpaymentYear] = useState(0);
  const [totalSalesYear, setTotalSalesYear] = useState(0);

  const [graphData, setGraphData] = useState(initialGraphData);

  useEffect(() => {
    const getData = async () => {
      await fetchOrders();
      setOrderSnapshot(querySnapshot);
      await fetchCustomers();
    };

    getData();
  }, [fetchOrders, querySnapshot, fetchCustomers]);

  const filterAndUpdateOrdersData = () => {
    if (!orderSnapshot) {
      return;
    }

    setGraphData(initialGraphData);

    let updatedGraphData = initialGraphData.map((item) => ({ ...item }));
    let totalSalesTillNow = 0;
    let totalCommissionTillNow = 0;
    let totalSalesThisMonth = 0;
    let totalSalesYear = 0;
    let totalCommissionYear = 0;
    let totalDownpaymentYear = 0;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    orderSnapshot.forEach((doc) => {
      const orderCost = doc.data().order_cost;
      const orderDate = doc.data().order_date;
      const orderCommission = doc.data().commission;
      const orderMonth = new Date(orderDate).getMonth() + 1;

      const orderFirmName = doc.data().firm_name;
      const isFinance = doc.data().finance;
      const orderFinancePartner = doc.data().financed_by;
      const orderYear = new Date(orderDate).getFullYear();

      totalSalesTillNow += parseInt(orderCost) || 0;
      totalCommissionTillNow += parseInt(orderCommission) || 0;

      if (
        orderMonth === currentMonth &&
        orderYear === currentYear &&
        firmName === "all_firms"
      ) {
        totalSalesThisMonth += parseInt(orderCost) || 0;
      } else if (
        orderMonth === currentMonth &&
        orderYear === currentYear &&
        firmName !== "all_firms" &&
        orderFirmName === firmName
      ) {
        totalSalesThisMonth += parseInt(orderCost) || 0;
      }

      // Extract month from the order date (assuming the date is in the format "YYYY-MM-DD")
      const month = new Date(orderDate).toLocaleString("en-US", {
        month: "short",
      });
      // Find the corresponding month in the existing graphData array
      const existingMonthIndex = graphData.findIndex(
        (item) => item.name === month
      );

      if (
        firmName !== "all_firms" &&
        firmName === orderFirmName &&
        orderYear === parseInt(year)
      ) {
        totalSalesYear += parseInt(orderCost) || 0;
        totalCommissionYear += parseInt(orderCommission) || 0;
        totalDownpaymentYear += parseInt(doc.data().downpayment) || 0;

        if (existingMonthIndex !== -1 && orderMonth) {
          updatedGraphData[existingMonthIndex].total +=
            parseInt(orderCost) || 0;
        }
      } else if (firmName === "all_firms" && orderYear === parseInt(year)) {
        totalSalesYear += parseInt(orderCost) || 0;
        totalCommissionYear += parseInt(orderCommission) || 0;
        totalDownpaymentYear += parseInt(doc.data().downpayment) || 0;

        // If the month is found, update the total in the graphData array
        if (existingMonthIndex !== -1) {
          updatedGraphData[existingMonthIndex].total +=
            parseInt(orderCost) || 0;
        }
      }
    });

    setGraphData(updatedGraphData);
    setTotalSales(totalSalesTillNow);
    setTotalCommission(totalCommissionTillNow);
    setTotalSalesThisMonth(totalSalesThisMonth);
    setTotalSalesYear(totalSalesYear);
    setTotalDownpaymentYear(totalDownpaymentYear);
    setTotalCommissionYear(totalCommissionYear);
  };

  useEffect(() => {
    filterAndUpdateOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmName, year, orderSnapshot]);

  const generateQuery = (collectionName, firmName) => {
    return query(
      collection(db, collectionName),
      where(
        collectionName === "customers" ? "firmName" : "firm_name",
        "==",
        firmName
      )
    );
  };

  useEffect(() => {
    if (firmName === "all_firms") {
      getCountFromServer(query(collection(db, "orders"))).then((res) => {
        setNumOrders(res.data().count);
      });
      getCountFromServer(query(collection(db, "customers"))).then((res) => {
        setNumCustomers(res.data().count);
      });
    } else {
      getCountFromServer(generateQuery("orders", firmName)).then((res) => {
        setNumOrders(res.data().count);
      });

      getCountFromServer(generateQuery("customers", firmName)).then((res) => {
        setNumCustomers(res.data().count);
      });
    }
  }, [firmName]);

  return (
    <>
      <ProtectedRoute>
        <AdminLayout>
          <div className="h-full overflow-x-auto overflow-y-auto">
            <LayoutHeader title={"Admin Dashboard"} />

            {loading && (
              <div className="flex justify-center w-full py-8">
                <PuffLoader size={60} />
              </div>
            )}

            {!loading && (
              <div className="flex items-center justify-end w-full">
                <div className="mr-4 space-y-2 md:mr-0 md:space-y-0 md:flex">
                  <div className="md:w-72">
                    <select
                      id="position"
                      required
                      value={firmName ?? ""}
                      onChange={(e) => setFirmName(e.target.value)}
                      className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                    >
                      <option value="all_firms">All Firms</option>
                      <option value="jk_solar_energy">JK Solar Energy</option>
                      <option value="jk_sale_and_service">
                        JK Sale and Service Agency
                      </option>
                      <option value="saaslynx">SaasLynx</option>
                    </select>
                  </div>
                  <div className="md:ml-4 md:w-72">
                    <select
                      id="position"
                      required
                      value={financePartner ?? ""}
                      onChange={(e) => setFinancePartner(e.target.value)}
                      className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                    >
                      <option value="all_partners">All Partners</option>
                      <option value="no_finance">None (Cash)</option>
                      <option value="j_and_k_bank">J&K Bank</option>
                      <option value="sbi_bank">SBI Bank</option>
                      <option value="hdfc_bank">HDFC Bank</option>
                      <option value="bajaj_finserv">Bajaj Finserv</option>
                      <option value="samsung_finance">Samsung Finance</option>
                    </select>
                  </div>
                  <div className="md:mx-4 md:w-36">
                    <select
                      id="position"
                      required
                      value={year ?? ""}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                    >
                      <option value="2024">Year 2024</option>
                      <option value="2023">Year 2023</option>
                      <option value="2022">Year 2022</option>
                      <option value="2021">Year 2021</option>
                      <option value="2020">Year 2020</option>
                      <option value="2019">Year 2019</option>
                      <option value="2018">Year 2018</option>
                      <option value="2017">Year 2017</option>
                      <option value="2016">Year 2016</option>
                      <option value="2015">Year 2015</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {!loading && (
              <div className="grid justify-center grid-cols-1 gap-4 px-4 py-4 md:grid-cols-3 lg:grid-cols-4">
                <DashboardCard
                  title={"Total Sales"}
                  num={formatAmountToInr(totalSales)}
                  icon={FcSalesPerformance}
                />
                <DashboardCard
                  hide
                  title={"Total Commission"}
                  num={formatAmountToInr(totalCommission)}
                  icon={FcSalesPerformance}
                />
                <DashboardCard
                  title={"Total Orders"}
                  num={numOrders}
                  icon={GiShoppingBag}
                />
                <DashboardCard
                  title={"Total Customers"}
                  num={numCustomers}
                  icon={FaUsers}
                />

                <div className="flex items-center justify-center col-span-1 p-4 bg-white border rounded-lg shadow-md md:col-span-3">
                  <Overview data={graphData} />
                </div>
                <div className="flex flex-col justify-between ">
                  <div className="mb-4 lg:mb-0"></div>
                  <div className="flex flex-col justify-end col-span-1 space-y-4">
                    <DashboardCard
                      title={"Sales (This Month)"}
                      num={formatAmountToInr(totalSalesThisMonth)}
                      icon={FcSalesPerformance}
                    />

                    <DashboardCard
                      title={`Sales (${year})`}
                      num={formatAmountToInr(totalSalesYear)}
                      icon={FcSalesPerformance}
                    />
                    <DashboardCard
                      hide={true}
                      title={`Commission (${year})`}
                      num={formatAmountToInr(totalCommissionYear)}
                      icon={FcSalesPerformance}
                    />
                    <DashboardCard
                      title={`Downpayment (${year})`}
                      num={formatAmountToInr(totalDownpaymentYear)}
                      icon={FcSalesPerformance}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </AdminLayout>
      </ProtectedRoute>
    </>
  );
}
