import React, { useState, useEffect, useMemo, useCallback } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { PuffLoader } from "react-spinners";
import CustomersTable from "../../components/CustomersTable";
import LayoutHeader from "../../components/LayoutHeader";

import useCustomersStore from "../../utils/useCustomersStore";
import { TbRefresh } from "react-icons/tb";

export default function Customers() {
  const { docs, fetchCustomers, loading, setCached, setLoading } =
    useCustomersStore();

  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCustomers, setTotalCustomers] = useState(0);

  const updateCustomers = useCallback((newCustomers) => {
    setCustomers(newCustomers);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    const getData = async () => {
      await fetchCustomers();
      setTotalCustomers(docs.length);
      updateCustomers(docs);
    };

    getData();
  }, [docs, fetchCustomers, updateCustomers]);

  const handleRefreshData = async () => {
    setLoading(true);
    setCached(false);
    await fetchCustomers();
  };

  const filteredCustomers = useMemo(
    () =>
      customers.filter(
        (customer) =>
          customer.customer_name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (customer.customer_phone_no &&
            customer.customer_phone_no.includes(searchQuery))
      ),
    [customers, searchQuery]
  );

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-x-auto overflow-y-auto ">
          <LayoutHeader title={"Customers"} />
          <div className="mx-4 header">
            <div className="items-center justify-between py-2 space-y-2 md:space-y-0 md:flex">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Customer's name or Phone number"
                className="w-full px-2 py-2 text-gray-600 border rounded-md md:w-45 sm:w-96"
              />
              <div className="flex flex-row items-center mr-10 space-x-4">
                <p className="text-sm font-semibold text-gray-600">
                  Total Customers:{" "}
                  <span className="text-lg font-bold">{totalCustomers}</span>
                </p>
                <button
                  onClick={handleRefreshData}
                  className="px-2 py-2 bg-blue-900 rounded-md hover:bg-blue-950"
                >
                  <div className="flex items-center space-x-2 text-white ">
                    <TbRefresh />
                  </div>
                </button>
              </div>
            </div>
          </div>

          {loading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}

          {!loading && <CustomersTable customers={filteredCustomers} />}
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
