import React, { useContext } from "react";
import UserProtectedRoute from "../../components/UserProtectedRoute";
import UserLayout from "../../components/UserLayout";
import { AuthContext } from "../../utils/authContext";
import UserDetailsCard from "../../components/UserDetailsCard";
import LayoutHeader from "../../components/LayoutHeader";

const UserDashboard = ({ currentUser }) => {
  return (
    <UserProtectedRoute>
      <UserLayout>
        <div className="h-full overflow-x-auto overflow-y-auto">
          <LayoutHeader title={"User Dashboard"} />
          <div className="py-10">
            <UserDetailsCard
              email={currentUser?.email ?? ""}
              isVerified={currentUser?.emailVerified}
              displayName={currentUser?.displayName ?? ""}
              lastLogin={currentUser?.metadata?.lastSignInTime ?? ""}
              photoUrl={currentUser?.photoURL ?? ""}
            />
          </div>
        </div>
      </UserLayout>
    </UserProtectedRoute>
  );
};

const UserDashboardWrapper = () => {
  const { currentUser } = useContext(AuthContext);
  return <UserDashboard currentUser={currentUser} />;
};

export default UserDashboardWrapper;
