import React, { useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { FaSpinner } from "react-icons/fa";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";
import EmployeeDetails from "../components/EmployeeDetails";

export default function Verify() {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [branch, setBranch] = useState("");

  const handleVerify = async () => {
    if (code !== "") {
      try {
        setIsVerified(false);
        setIsActive(false);
        setLoading(true);

        const q = query(collection(db, "employee_codes"));
        const querySnapshot = await getDocs(q);

        let isValidCode = false;

        querySnapshot.forEach((doc) => {
          if (doc.data().employee_code === code) {
            setIsVerified(true);
            setIsActive(doc.data().active);
            setEmployeeName(doc.data().employee_name);
            setEmployeeCode(doc.data().employee_code);
            setPhotoUrl(doc.data().photoUrl);
            setBranch(doc.data().branch);
            isValidCode = true;
          }
        });

        if (!isValidCode) {
          setIsVerified(false);
          setIsActive(false);
          setEmployeeName("");
          setEmployeeCode("");
          setPhotoUrl("");
          setBranch("");
          toast.error("Invalid employee code", toastOptions);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please enter employee code", toastOptions);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen text-white bg-blue-900">
      <img src="/assets/logo.svg" alt="Logo" className="mb-4" />
      <h1 className="mb-4 text-2xl font-bold">Employee Verification</h1>
      <div className="w-full max-w-xs">
        <div className="flex items-center">
          <div className="w-3/4 overflow-hidden border rounded-lg">
            <input
              type="text"
              id="code"
              className="w-full px-3 py-2 text-white bg-transparent focus:outline-none"
              placeholder="Enter Employee Code"
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
            />
          </div>

          <button
            className="px-6 py-3 ml-4 font-bold text-blue-900 transition duration-300 bg-white rounded-full hover:bg-gray-200 focus:outline-none"
            onClick={handleVerify}
          >
            {loading ? (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            ) : (
              "Verify"
            )}
          </button>
        </div>
      </div>

      <EmployeeDetails
        isVerified={isVerified}
        isActive={isActive}
        employeeName={employeeName}
        photoUrl={photoUrl}
        branch={branch}
        employeeCode={employeeCode}
      />
    </div>
  );
}
