import React, { useState } from "react";
import EmployeeModal from "./EmployeeModal";

export default function EmployeeTable({ employees }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleOnClick = (employee) => {
    setData(employee);
    setIsModalOpen(true);
  };

  return (
    <>
      {data && (
        <EmployeeModal
          id={data}
          data={data}
          setData={setData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Position
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Email Address
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Address
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              ID Issued
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {employees.map((employee) => (
            <tr key={employee.id}>
              <td
                className={`px-6 py-4 text-sm font-medium cursor-pointer ${
                  employee.is_active ? "text-green-600" : "text-red-600"
                } whitespace-nowrap hover:underline `}
                onClick={() => handleOnClick(employee)}
              >
                {employee.employee_code}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.employee_name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.position}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.phone_no ? employee.phone_no : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.email_id ? employee.email_id : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.address
                  ? employee.address.length > 33
                    ? employee.address.substring(0, 30) + "..."
                    : employee.address
                  : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {employee.id_issued ? "Yes" : "No"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
