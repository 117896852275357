import React from "react";

const EmployeeDetails = ({
  isVerified,
  isActive,
  employeeName,
  photoUrl,
  branch,
  employeeCode,
}) => {
  return (
    <div className="flex flex-col items-center m-8">
      {!isVerified && (
        <div className="mt-4 font-bold text-red-500">
          Employee code is not verified.
        </div>
      )}

      {isVerified && !isActive && (
        <div className="mt-4 font-bold text-red-500">
          Employee is not active.
        </div>
      )}

      {isVerified && isActive && (
        <div className="p-6 bg-white rounded-lg shadow-lg md:px-20">
          <div className="text-center">
            <div className="text-lg font-bold text-green-500">
              Employee is active.
            </div>
            <div className="mt-4">
              <img
                src={photoUrl}
                alt={photoUrl}
                className="w-32 h-32 mx-auto rounded-full shadow-md"
              />
            </div>
            <div className="mt-6 text-center">
              <div className="text-xl font-semibold text-gray-500">
                {employeeName}
              </div>
              <div className="mt-2 text-gray-500">
                Branch: <span className="font-medium">{branch}</span>
              </div>
              <div className="mt-1 text-gray-500">
                Code: <span className="font-medium">{employeeCode}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetails;
