import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { FaSpinner } from "react-icons/fa";
import { AuthContext } from "../utils/authContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

const defaultPhotoUrl =
  "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";

export default function UserDetailsCard({
  email,
  isVerified,
  displayName,
  lastLogin,
  photoUrl,
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // const toggleModalOpen = () => {
  //   setIsEditModalOpen(true);
  // };

  const toggleModalClose = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div className="w-full mx-auto overflow-hidden bg-white rounded-lg shadow-lg md:w-3/4 lg:w-1/2 xl:w-1/3">
      <UsersDetailsEditModal
        isOpen={isEditModalOpen}
        toggleModalClose={toggleModalClose}
        displayName={displayName}
        photoUrl={photoUrl}
      />
      <img
        className="object-contain w-full h-52"
        src={photoUrl ?? defaultPhotoUrl}
        alt={`${displayName} profile`}
      />
      <div className="p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800">
            {!displayName ? "Edit your Name" : displayName}
          </h2>
          {isVerified && (
            <span className="font-semibold text-green-500">Verified</span>
          )}
        </div>
        <p className="text-sm text-gray-600">{email}</p>
        <p className="mt-2 text-sm text-gray-600">Last Login: {lastLogin}</p>
        {/* <button
          onClick={toggleModalOpen}
          className="px-4 py-2 mt-4 text-white bg-blue-900 rounded-lg shadow-md hover:bg-blue-700"
        >
          Edit Profile
        </button> */}
      </div>
    </div>
  );
}

const UsersDetailsEditModal = ({
  isOpen,
  toggleModalClose,
  displayName,
  photoUrl,
}) => {
  const [usersName, setUsersName] = useState(displayName || "");
  const [usersPhotoUrl, setUsersPhotoUrl] = useState(photoUrl || "");
  const [imageUploading, setImageUploading] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  const { currentUser, updateUserProfile, sendVerificationEmail } =
    useContext(AuthContext);

  const storage = getStorage();
  const handlePhotoChange = (e) => {
    try {
      const file = e.target.files[0];
      setImageUploading(true);
      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
      } else {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000);
        const storageRef = ref(storage, `/users/${timestamp}-${randomNum}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => console.error(err),
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask?.snapshot?.ref);

            setUsersPhotoUrl(downloadUrl);
            setImageUploading(false);
          }
        );
      }
    } catch (error) {
      setImageUploading(false);
      console.error(error);
    }
  };

  const updateUsersNameAndPhotoInFirebase = async () => {
    const updatedData = {
      displayName: usersName,
      photoURL: usersPhotoUrl,
    };

    try {
      const updateUserRef = doc(db, "users", currentUser?.uid);
      await updateDoc(updateUserRef, updatedData);
      toast.success("Profile updated successfully!", toastOptions);
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    try {
      setSaveBtnClicked(true);
      if (currentUser !== null) {
        updateUserProfile(usersName, usersPhotoUrl);
        await updateUsersNameAndPhotoInFirebase();
        sendVerificationEmail();
        setSaveBtnClicked(false);
        toggleModalClose();
      }
    } catch (error) {
      setSaveBtnClicked(false);
      toast.error("Something went wrong!", toastOptions);
      console.error(error);
    }
  };
  return (
    <div
      className={`fixed mx-4 inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="w-full max-w-md mx-4 bg-white rounded-lg shadow-lg">
        <form onSubmit={handleSubmit} className="p-4">
          <h2 className="mb-4 text-xl font-semibold text-gray-800">
            Edit Profile
          </h2>
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="w-full px-3 py-2 leading-tight border rounded appearance-none focus:outline-none focus:shadow-outline"
              type="text"
              id="name"
              value={usersName}
              onChange={(e) => setUsersName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="photo"
            >
              Photo
            </label>
            <div className="flex items-center">
              {!imageUploading ? (
                <img
                  className="object-cover w-12 h-12 mr-4 rounded-full"
                  src={usersPhotoUrl}
                  alt="Profile"
                />
              ) : (
                <FaSpinner className="mx-2 animate-spin" size={20} />
              )}

              <input
                type="file"
                id="photo"
                accept="image/*"
                onChange={handlePhotoChange}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-4 font-medium text-gray-500"
              onClick={toggleModalClose}
            >
              Cancel
            </button>
            {!saveBtnClicked ? (
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-900 rounded-lg hover:bg-blue-700"
              >
                Save
              </button>
            ) : (
              <FaSpinner
                className="mx-2 text-blue-900 animate-spin"
                size={20}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
