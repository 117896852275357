import React from "react";

const BankAccount = ({ bankName, accountNumber, ifscCode, logo }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="flex items-center">
        <img src={logo} alt={bankName} className="w-10 h-10 mr-2" />
      </div>
      <div className="flex flex-col">
        <h1 className="text-xs font-bold uppercase">{bankName}</h1>
        <h1 className="text-xs font-bold text-blue-600 uppercase">
          {accountNumber}
        </h1>
        <h1 className="text-xs font-semibold uppercase">
          IFSC Code: {ifscCode}
        </h1>
      </div>
    </div>
  );
};

export default BankAccount;
