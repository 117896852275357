import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useCallback } from "react";
import { AiFillEdit } from "react-icons/ai";
import { RiBillLine } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import useOrdersStore from "../utils/useOrdersStore";

export default function OrderModal({
  ref: componentRef,
  data,
  isModalOpen,
  setIsModalOpen,
}) {
  const { deleteOrder } = useOrdersStore();
  const {
    id: orderId,
    advisor_code: advisorCode,
    advisor_name: advisorName,
    customer_name: customerName,
    customer_address: customerAddress,
    customer_phone_no: customerPhoneNo,
    customer_alternate_phone_no: customerAlternatePhoneNo,
    customer_aadhar_no: customerAadharNo,
    customer_pan_no: customerPanNo,
    customer_parentage: customerParentage,
    customer_bank_account_no: customerBankAccountNo,
    customer_bank_name: customerBankName,
    customer_bank_ifsc_code: customerBankIfscCode,
    customer_photo_url: customerPhotoUrl,
    customer_occupation: customerOccupation,
    customer_qualification: customerQualification,
    guarantor,
    guarantor_name: guarantorName,
    guarantor_pan_no: guarantorPanNo,
    guarantor_aadhar_no: guarantorAadharNo,
    guarantor_address: guarantorAddress,
    guarantor_bank_account_no: guarantorBankAccountNo,
    guarantor_bank_name: guarantorBankName,
    guarantor_bank_ifsc_code: guarantorIfscCode,
    guarantor_parentage: guarantorParentage,
    guarantor_phone_no: guarantorPhoneNo,
    guarantor_photo_url: guarantorPhotoUrl,
    guarantor_occupation: guarantorOccupation,
    guarantor_qualification: guarantorQualification,
    finance,
    financed_by: financedBy,
    order_details: orderDetails,
    order_date: orderDate,
    order_cost: orderCost,
    order_notes: orderNotes,
    payment_method: paymentMethod,
    downpayment,
    transaction_id: transactionId,
    order_status: orderStatus,
    customer_id: customerId,
    performaBillNo,
    challanNo,
    chequeDate,
    chequeNumber,
    chequeBankName,
    commission,
  } = data;

  const navigate = useNavigate();

  const handleUpdateOrderStatus = useCallback(
    async (e) => {
      const data = {
        order_status: e.target.value,
      };
      try {
        const updateOrderRef = doc(db, "orders", orderId);
        await updateDoc(updateOrderRef, data);
        toast.success("Updated Status successfully!", toastOptions);
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong!", toastOptions);
      }
    },
    [orderId]
  );

  const handleDeleteOrder = useCallback(async () => {
    try {
      const deleteOrderRef = doc(db, "orders", orderId);
      await deleteDoc(deleteOrderRef);
      toast.success("Order deleted successfully!", toastOptions);
      deleteOrder(orderId);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong!", toastOptions);
    } finally {
      setIsModalOpen(false);
    }
  }, [orderId, setIsModalOpen, deleteOrder]);

  return (
    <Modal isOpen={isModalOpen} ariaHideApp={false}>
      <div className="p-4" ref={(el) => el && (componentRef = el)}>
        <div className="flex justify-between">
          <h1 className="mb-4 text-2xl font-bold">Order Details</h1>
          <div className="flex items-center justify-end space-x-1 md:space-x-4 actions">
            <button
              onClick={() => navigate(`/admin/bill/${orderId}`)}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
            >
              <RiBillLine className="w-5 h-5 mr-2" />
              Billing
            </button>
            <Link
              to={`/admin/edit-order/${orderId}`}
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
            >
              <AiFillEdit className="w-5 h-5 mr-2" />
              <span className="hidden md:block">Edit</span>
            </Link>
            <button
              onClick={handleDeleteOrder}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
            >
              <FaTrash className="w-5 h-5 mr-2" />
              <span className="hidden md:block">Delete</span>
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              type="button"
              className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
            >
              <MdOutlineCancel className="w-5 h-5 mr-2" />
              <span className="hidden md:block">Close</span>
            </button>
          </div>
        </div>
        <div className="mb-6">
          <h2 className="mb-2 text-lg font-bold ">Order Information</h2>
          <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-3">
            <div>
              <p className="font-bold">Order Id</p>
              <p>{orderId}</p>
            </div>
            <div>
              <p className="font-bold">Performa Bill No.</p>
              <p>{performaBillNo}</p>
            </div>
            <div>
              <p className="font-bold">Challan No.</p>
              <p>{challanNo === "" ? "N/A" : challanNo}</p>
            </div>
            <div>
              <p className="font-bold">Details</p>
              <p>{orderDetails}</p>
            </div>

            <div>
              <p className="font-bold">Date</p>
              <p>{orderDate}</p>
            </div>
            <div>
              <p className="font-bold">Cost</p>
              <p>{`₹ ${orderCost}`}</p>
            </div>
            <div>
              <p className="font-bold">Notes</p>
              <p>{orderNotes === "" ? "N/A" : orderNotes}</p>
            </div>

            <div>
              <p className="font-bold">Downpaymemt</p>
              <p>{downpayment === "" ? "N/A" : downpayment}</p>
            </div>

            <div>
              <p className="font-bold">Order Status</p>
              <div className="mb-4 md:w-1/2">
                <select
                  id="position"
                  value={orderStatus ?? ""}
                  onChange={handleUpdateOrderStatus}
                  className="w-full px-3 font-semibold border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                >
                  <option value="pending">Pending</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <div>
              <p className="font-bold">Payment Method</p>
              <p>{paymentMethod}</p>
            </div>
            {(paymentMethod === "upi" || paymentMethod === "card") && (
              <div>
                <p className="font-bold">Transaction Id</p>
                <p>{transactionId}</p>
              </div>
            )}
            {paymentMethod === "cheque" && (
              <div>
                <div>
                  <p className="font-bold">Cheque Number</p>
                  <p>{chequeNumber}</p>
                </div>
                <div>
                  <p className="font-bold">Cheque Bank Name</p>
                  <p>{chequeBankName}</p>
                </div>
                <div>
                  <p className="font-bold">Cheque Date</p>
                  <p>{chequeDate}</p>
                </div>
              </div>
            )}
            <div className="no-print">
              <p className="font-bold">Commission</p>
              <p>{commission === "" ? "N/A" : commission}</p>
            </div>
          </div>
        </div>
        <hr className="py-4" />
        <div className="mb-6">
          <h2 className="mb-2 text-lg font-bold ">Customer Information</h2>
          <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-3">
            <div>
              <p className="font-bold">Name:</p>
              <p>{customerName}</p>
            </div>
            <div>
              <p className="font-bold">Parentage: </p>
              <p>{customerParentage}</p>
            </div>
            <div>
              <p className="font-bold">Photo: </p>
              {customerPhotoUrl && (
                <img
                  src={customerPhotoUrl}
                  alt="customer_photo"
                  className="w-20 mt-4 rounded-lg md:w-40"
                />
              )}
            </div>
            <div>
              <p className="font-bold">Customer Id:</p>
              <p>{customerId}</p>
            </div>

            <div>
              <p className="font-bold">Address:</p>
              <p>{customerAddress}</p>
            </div>
            <div>
              <p className="font-bold">Phone No:</p>
              <p>{customerPhoneNo}</p>
            </div>
            <div>
              <p className="font-bold">Alternate Phone No:</p>
              <p>{customerAlternatePhoneNo}</p>
            </div>
            <div>
              <p className="font-bold">Occupation:</p>
              <p>{customerOccupation}</p>
            </div>
            <div>
              <p className="font-bold">Qualification:</p>
              <p>{customerQualification}</p>
            </div>
            <div>
              <p className="font-bold">Aadhar No:</p>
              <p>{customerAadharNo === "" ? "N/A" : customerAadharNo}</p>
            </div>
            <div>
              <p className="font-bold">PAN No: </p>
              <p>{customerPanNo === "" ? "N/A" : customerPanNo}</p>
            </div>

            <div>
              <p className="font-bold">Bank Account No: </p>
              <p>
                {customerBankAccountNo === "" ? "N/A" : customerBankAccountNo}
              </p>
            </div>
            <div>
              <p className="font-bold">Bank Name: </p>
              <p>{customerBankName === "" ? "N/A" : customerBankName}</p>
            </div>
            <div>
              <p className="font-bold">Bank IFSC Code: </p>
              <p>
                {customerBankIfscCode === "" ? "N/A" : customerBankIfscCode}
              </p>
            </div>
          </div>
        </div>
        {guarantor && (
          <div className="mb-6">
            <hr className="py-4" />
            <h2 className="mb-2 text-lg font-bold ">Guarantor Information</h2>
            <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-3">
              <div>
                <p className="font-bold">Name:</p>
                <p>{guarantorName}</p>
              </div>
              <div>
                <p className="font-bold">Parentage: </p>
                <p>{guarantorParentage}</p>
              </div>
              <div>
                <p className="font-bold">Photo: </p>
                {guarantorPhotoUrl && (
                  <img
                    src={guarantorPhotoUrl}
                    alt="customer_photo"
                    className="w-20 mt-4 rounded-lg md:w-40"
                  />
                )}
              </div>
              <div>
                <p className="font-bold">Address:</p>
                <p>{guarantorAddress}</p>
              </div>
              <div>
                <p className="font-bold">Phone No:</p>
                <p>{guarantorPhoneNo}</p>
              </div>
              <div>
                <p className="font-bold">Occupation:</p>
                <p>{guarantorOccupation}</p>
              </div>
              <div>
                <p className="font-bold">Qualification:</p>
                <p>{guarantorQualification}</p>
              </div>
              <div>
                <p className="font-bold">Aadhar No:</p>
                <p>{guarantorAadharNo === "" ? "N/A" : guarantorAadharNo}</p>
              </div>
              <div>
                <p className="font-bold">PAN No:</p>
                <p>{guarantorPanNo === "" ? "N/A" : guarantorPanNo}</p>
              </div>

              <div>
                <p className="font-bold">Bank Account No: </p>
                <p>
                  {guarantorBankAccountNo === ""
                    ? "N/A"
                    : guarantorBankAccountNo}
                </p>
              </div>
              <div>
                <p className="font-bold">Bank Name: </p>
                <p>{guarantorBankName === "" ? "N/A" : guarantorBankName}</p>
              </div>
              <div>
                <p className="font-bold">IFSC Code: </p>
                <p>{guarantorIfscCode === "" ? "N/A" : guarantorIfscCode}</p>
              </div>
            </div>
          </div>
        )}
        {finance && (
          <div className="mb-6">
            <hr className="py-4" />
            <h2 className="mb-2 text-lg font-bold ">Finance Information</h2>
            <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-3">
              <div>
                <p className="font-bold">Financed By:</p>
                <p>{financedBy}</p>
              </div>
            </div>
          </div>
        )}
        <hr className="py-4" />
        <div className="mb-6">
          <h2 className="mb-2 text-lg font-bold">Advisor Information</h2>
          <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-3">
            <div>
              <p className="font-bold">Advisor Code:</p>
              <p>{advisorCode}</p>
            </div>
            <div>
              <p className="font-bold">Advisor Name:</p>
              <p>{advisorName}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
