import { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { FaSpinner } from "react-icons/fa";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toast } from "react-toastify";
import { formatAmountToInr, toastOptions } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

const AdvisorModal = ({ data, isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();

  const [advisorName, setAdvisorName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [idIssued, setIdIssued] = useState("");
  const [isActive, setIsActive] = useState("");
  const [advisorCode, setAdvisorCode] = useState("");

  const [updateBtnClicked, setUpdateBtnClicked] = useState(false);

  useEffect(() => {
    setAdvisorName(data.advisor_name);
    setAddress(data.address);
    setPhoneNo(data.phone_no);
    setEmailId(data.email_id);
    setIdIssued(data.id_issued);
    setIsActive(data.is_active);
    setPhotoUrl(data.photoUrl ?? defaultPhotoUrl);
    setAdvisorCode(data.advisor_code);
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateBtnClicked(true);
    const updatedData = {
      advisor_name: advisorName,
      address: address,
      phone_no: phoneNo,
      email_id: emailId,
      id_issued: idIssued,
      is_active: isActive,
      photoUrl: photoUrl,
    };
    try {
      const updateRef = doc(db, "advisor_list", data.id);
      await updateDoc(updateRef, updatedData);
      setUpdateBtnClicked(false);
      setIsModalOpen(false);
    } catch (error) {
      setUpdateBtnClicked(false);
      console.error(error);
    }
  };

  const defaultPhotoUrl =
    "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";

  const storage = getStorage();

  const [photoUrl, setPhotoUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const handleImageUpload = useCallback(
    (e) => {
      try {
        const file = e.target.files[0];
        setImageUploading(true);
        if (!file) {
          toast.error("Please choose a file first!", toastOptions);
        } else {
          const timestamp = Date.now();
          const randomNum = Math.floor(Math.random() * 1000);
          const storageRef = ref(
            storage,
            `/advisors/${timestamp}-${randomNum}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => console.error(err),
            async () => {
              const downloadUrl = await getDownloadURL(
                uploadTask?.snapshot?.ref
              );

              setPhotoUrl(downloadUrl);
              setImageUploading(false);
            }
          );
        }
      } catch (error) {
        setPhotoUrl(defaultPhotoUrl);
        console.error(error);
      }
    },
    [storage]
  );

  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommision, setTotalCommision] = useState(0);

  const fetchOrdersOfAdvisor = async () => {
    if (advisorCode === "") {
      return;
    }
    const ordersCol = collection(db, "orders");
    const ordersQuery = query(
      ordersCol,
      where("advisor_code", "==", advisorCode)
    );
    const res = await getDocs(ordersQuery);

    const totalCost = res.docs.reduce(
      (acc, doc) => acc + (parseInt(doc.data().order_cost) || 0),
      0
    );

    const totalCommission = res.docs.reduce(
      (acc, doc) => acc + (parseInt(doc.data().commission) || 0),
      0
    );

    setTotalOrders(res.docs.length);
    setTotalAmount(totalCost);
    setTotalCommision(totalCommission);
  };

  useEffect(() => {
    fetchOrdersOfAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorCode]);

  return (
    <Modal isOpen={isModalOpen} ariaHideApp={false}>
      <div className="p-4">
        <h2 className="mb-4 text-2xl font-bold">Edit Data</h2>
        <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
          <div className="w-full md:w-1/2 md:px-4">
            <div className="mb-4">
              <label
                className="block mb-2 font-bold text-gray-700"
                htmlFor="advisorName"
              >
                Advisor Name:
              </label>
              <input
                type="text"
                id="advisorName"
                placeholder="Enter Advisor Name"
                value={advisorName ?? ""}
                onChange={(e) => setAdvisorName(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block mb-2 font-bold text-gray-700"
                htmlFor="phoneNo"
              >
                Phone Number:
              </label>
              <input
                type="text"
                id="phoneNo"
                placeholder="Enter Phone Number"
                maxLength={10}
                value={phoneNo ?? ""}
                onChange={(e) => setPhoneNo(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="flex items-center w-full space-x-8 md:w-1/3 md:px-4 ">
            {photoUrl && (
              <img
                src={photoUrl}
                alt="user"
                className="w-20 mt-4 rounded-lg md:w-40 "
              />
            )}
            {!imageUploading ? (
              <div className="text-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                  id="image-upload"
                />
                <label
                  htmlFor="image-upload"
                  className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                >
                  Upload
                </label>
              </div>
            ) : (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            )}
          </div>
        </div>
        <div className="md:space-x-2 md:flex">
          <div className="mb-4 md:w-1/2">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="address"
            >
              Address:
            </label>
            <input
              type="text"
              id="address"
              value={address ?? ""}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4 md:w-1/2">
            <label
              className="block mb-2 font-bold text-gray-700"
              htmlFor="emailId"
            >
              Email ID:
            </label>
            <input
              type="text"
              id="emailId"
              value={emailId ?? ""}
              onChange={(e) => setEmailId(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <div className="flex flex-col mb-4">
          <div className="font-bold text-gray-700">
            Total Orders:{" "}
            <span className="ml-4 text-xl font-semibold ">{totalOrders}</span>
          </div>
          <div className="font-bold text-gray-700">
            Total Sales:{" "}
            <span className="ml-4 text-xl font-semibold">
              {formatAmountToInr(totalAmount)}
            </span>
          </div>
          <div className="font-bold text-gray-700">
            Total Commission:{" "}
            <span className="ml-4 text-xl font-semibold">
              {formatAmountToInr(totalCommision)}
            </span>
          </div>
          <div>
            <button
              type="button"
              onClick={
                totalOrders !== 0
                  ? () => navigate(`/admin/advisor/orders/${advisorCode}`)
                  : null
              }
              className="flex items-center justify-center px-4 py-2 mt-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600 focus:outline-none focus:shadow-outline"
            >
              See Details <FaExternalLinkSquareAlt className="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="inline-flex items-center font-bold text-gray-700">
            <input
              type="checkbox"
              className="w-5 h-5 text-blue-600 form-checkbox"
              checked={idIssued ?? false}
              onChange={(e) => setIdIssued(e.target.checked)}
            />
            <span className="ml-2">ID Issued</span>
          </label>
        </div>
        <div className="mb-4">
          <label className="inline-flex items-center font-bold text-gray-700">
            <input
              type="checkbox"
              className="w-5 h-5 text-blue-600 form-checkbox"
              checked={isActive ?? false}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <span className="ml-2">Is Active</span>
          </label>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setIsModalOpen(false)}
            type="button"
            className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600 focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-blue-900 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
          >
            {updateBtnClicked && (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            )}
            {!updateBtnClicked && "Update"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdvisorModal;
