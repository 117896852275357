import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";
import { PuffLoader } from "react-spinners";
import { FaMinus, FaPlus, FaPrint } from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { MdCalculate } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { ToWords } from "to-words";
import BankAccount from "../../components/BankAccount";

export const jkse_accounts = [
  {
    bankName: "J&K Bank",
    accountNumber: "0814020100000106",
    ifscCode: "JAKA0JANGLA",
    logo: "/assets/jkbank.png",
  },
  {
    bankName: "HDFC Bank",
    accountNumber: "50200023870097",
    ifscCode: "HDFC0000858",
    logo: "/assets/hdfcbank.png",
  },
  {
    bankName: "State Bank of India",
    accountNumber: "36564332799",
    ifscCode: "SBIN0001478",
    logo: "/assets/sbibank.png",
  },
];

export const jkss_accounts = [
  {
    bankName: "J&K Bank",
    accountNumber: "0837020100000039",
    ifscCode: "JAKA0SHANKE",
    logo: "/assets/jkbank.png",
  },
  {
    bankName: "HDFC Bank",
    accountNumber: "50200079868973",
    ifscCode: "HDFC0000858",
    logo: "/assets/hdfcbank.png",
  },
  {
    bankName: "State Bank of India",
    accountNumber: "42626811745",
    ifscCode: "SBIN0001478",
    logo: "/assets/sbibank.png",
  },
];

export const Bill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [prevBill, setPrevBill] = useState("");
  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [billType, setBillType] = useState("bill_performa");

  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [advancePayment, setAdvancePayment] = useState(0);

  const [tableData, setTableData] = useState([
    {
      sno: 1,
      description: "",
      qty: 1,
      rate: 0.0,
      cgst: 9,
      sgst: 9,
      totalGSTAmount: 0.0,
      totalAmount: 0.0,
    },
  ]);

  const updateGrandTotal = (data) => {
    const totalAmount = data.reduce((acc, item) => acc + item.totalAmount, 0);
    setTotal(parseFloat(totalAmount));
    setGrandTotal(parseFloat(totalAmount) - parseFloat(advancePayment));
  };

  const handleCellChange = (rowIndex, field, value) => {
    const updatedTableData = tableData.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleAddRow = () => {
    const newRowIndex = tableData.length + 1;
    const newRow = {
      sno: newRowIndex,
      description: "",
      qty: 1,
      rate: 0.0,
      cgst: 9,
      sgst: 9,
      totalGSTAmount: 0.0,
      totalAmount: 0.0,
    };

    setTableData([...tableData, newRow]);
  };

  const handleRemoveRow = () => {
    if (tableData.length > 0) {
      const updatedTableData = [...tableData];
      updatedTableData.pop();
      setTableData(updatedTableData);
    }
  };

  const handleCalculate = () => {
    const updatedTableData = [...tableData];
    updatedTableData.forEach((row) => {
      row.totalAmount =
        row.rate * row.qty + (row.rate * row.qty * (row.cgst + row.sgst)) / 100;
    });
    setTableData(updatedTableData);
    updateGrandTotal(updatedTableData);
  };

  const fetchBillData = async () => {
    if (id === undefined) return;
    try {
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setName(docSnap.data().customer_name);
        setPhoneNo(docSnap.data().customer_phone_no);
        setAddress(docSnap.data().customer_address);
        setDate(docSnap.data().order_date);
        setFirmName(docSnap.data().firm_name);
      } else {
        toast.error("No such document exists!", toastOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBillNumber = async () => {
    const q = query(collection(db, "codes"));
    const querySnapshot = await getDocs(q);
    setPrevBill(querySnapshot.docs[1].data().bill_number);
  };

  useEffect(() => {
    fetchBillData();
    fetchBillNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatBillName = (firmName, billType, billNo, name) => {
    let formattedBillType = "";

    switch (billType) {
      case "bill_performa":
        formattedBillType = "Bill Performa";
        break;
      case "tax_invoice":
        formattedBillType = "Tax Invoice";
        break;
      case "estimate":
        formattedBillType = "Estimate";
        break;
      default:
        formattedBillType = "Unknown Bill Type";
    }

    const firm = firmName === "jk_solar_energy" ? "JKSE" : "JKSS";

    return `${firm} - ${formattedBillType} - ${billNo} - ${name}`;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: formatBillName(firmName, billType, prevBill, name),
    pageStyle: `
      @media print {
        .pagebreak {
          page-break-before: always;
      }
      .no-print {
        display: none !important;
      }
    }
  `,
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    handleCalculate();
    const data = {
      name: name,
      phoneNo: phoneNo,
      address: address,
      date: date,
      billNo:
        firmName === "jk_solar_energy"
          ? "JKSE" + prevBill.toString()
          : "JKSS" + prevBill.toString(),
      firmName: firmName,
      billType: billType,
      total: total,
      advancePayment: advancePayment,
      grandTotal: grandTotal,
      tableData: tableData,
    };

    try {
      const docRef = doc(db, "billing", id);
      const billDoc = await getDoc(docRef);
      if (!billDoc.exists()) {
        const newDocRef = doc(db, "billing", id);
        await setDoc(newDocRef, data);

        // update bill number
        const updateRef = doc(db, "codes", "last_bill_number");
        await updateDoc(updateRef, { bill_number: prevBill + 1 });

        toast.success("Bill saved successfully!", toastOptions);
      } else {
        await updateDoc(docRef, data);
        toast.success("Bill updated successfully!", toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.code, toastOptions);
    }
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const logo =
    firmName === "jk_solar_energy"
      ? "/assets/jkse_logo.png"
      : "/assets/jkss_logo.png";

  const gstin =
    firmName === "jk_solar_energy" ? "01BTVPK3249N2Z5" : "01ATXPA5399N1Z0";

  return (
    <ProtectedRoute>
      <AdminLayout>
        {isLoading ? (
          <div className="flex justify-center w-full py-8">
            <PuffLoader size={60} />
          </div>
        ) : (
          <div className="h-full max-w-3xl pb-4 mx-auto overflow-x-auto overflow-y-auto bg-white">
            <div className="flex flex-row justify-between mx-4 mt-4">
              <button
                onClick={handleBack}
                type="button"
                className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
              >
                <IoChevronBackCircle className="w-5 h-5 mr-2" />
                Back
              </button>

              <div className="flex flex-row justify-end space-x-4">
                <button
                  onClick={handleSave}
                  type="button"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
                >
                  <FaPrint className="w-5 h-5 mr-2" />
                  Save
                </button>

                <button
                  onClick={handlePrint}
                  type="button"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700"
                >
                  <FaPrint className="w-5 h-5 mr-2" />
                  Print
                </button>
              </div>
            </div>
            <div id="bill" ref={componentRef} className="flex flex-col mx-4">
              <div className="flex flex-row items-center justify-between px-2 py-1 mt-4 border-2 ">
                <img alt="logo" src={logo} width="120px" />
                <div className="flex flex-col items-center justify-center mx-4">
                  <select
                    id="position"
                    required
                    value={firmName}
                    onChange={(e) => setFirmName(e.target.value)}
                    className="w-full px-3 py-1 text-3xl font-bold text-center uppercase bg-transparent border-0 appearance-none focus:outline-none focus:shadow-outline"
                  >
                    <option value="jk_solar_energy">JK Solar Energy</option>
                    <option value="jk_sale_and_service">
                      JK Sale & Service Agency
                    </option>
                  </select>
                  <h1 className="text-xs font-semibold uppercase">
                    Naibasti Anantnag, Jammu & Kashmir
                    <span className="ml-4">Phone: +(91)6005016509</span>
                  </h1>
                  <h1 className="text-xs font-semibold uppercase">
                    <span className="pl-2 uppercase">
                      Email:{" "}
                      <span className="lowercase">care@jksolarenergy.in</span>
                    </span>
                    <span className="pl-2 uppercase">
                      Web: <span className="lowercase">jksolarenergy.in</span>
                    </span>
                  </h1>
                  <select
                    id="position"
                    required
                    value={billType ?? ""}
                    onChange={(e) => setBillType(e.target.value)}
                    className="px-3 py-1 mt-1 text-lg font-bold text-center uppercase bg-transparent border appearance-none w-52 focus:outline-none focus:shadow-outline"
                  >
                    <option value="bill_performa">Bill Performa</option>
                    <option value="tax_invoice">Tax Invoice</option>
                    <option value="estimate">Estimate</option>
                  </select>
                  <div className="flex flex-row items-center justify-between space-x-8">
                    <h3 className="mt-1 font-bold uppercase ">
                      GSTIN Number: {gstin}
                    </h3>
                  </div>
                </div>
                <img className="opacity-0" alt="logo" src={logo} width="60px" />
              </div>
              <div className="flex flex-row items-start justify-between w-full p-2 mt-1 text-xs font-medium border-2">
                <div className="w-[50%]  pr-2 flex flex-col">
                  <h1 className="font-bold underline ">Party Details : </h1>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="text-red-600 uppercase focus:outline-none focus:shadow-outline"
                  />

                  <textarea
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="uppercase focus:outline-none focus:shadow-outline"
                  />
                  <div className="mt-2">
                    <h1>
                      Mobile No :{" "}
                      <input
                        type="text"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        className=" focus:outline-none focus:shadow-outline"
                      />
                    </h1>
                  </div>
                </div>
                <div className="w-[50%] flex flex-col items-start justify-end px-4 border-l-2">
                  <h1>
                    Invoice No :{" "}
                    <span className="font-bold uppercase">
                      {firmName === "jk_solar_energy"
                        ? "JKSE" + prevBill.toString()
                        : "JKSS" + prevBill.toString()}
                    </span>
                  </h1>
                  <h1>
                    Dated :{" "}
                    <input
                      type="text"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="ml-2 w-[100px] font-bold uppercase  focus:outline-none focus:shadow-outline"
                    />
                  </h1>
                  <h1 className="mt-12">
                    Place of Supply : Anantnag, Jammu & Kashmir
                  </h1>
                </div>
              </div>
              <div>
                <table className="w-full mt-1 text-xs border-2 border-collapse">
                  <thead>
                    <tr>
                      <th className="w-4 px-1 border-2 ">S.N.</th>
                      <th className="px-1 border-2 w-44">
                        Description of Goods
                      </th>
                      <th className="w-4 px-1 border-2">Qty</th>
                      <th className="w-16 px-1 border-2">Rate</th>
                      <th className="px-1 border-2 w-7 ">CGST</th>
                      <th className="px-1 border-2 w-7 ">SGST</th>
                      <th className="w-16 px-1 border-2 ">Total GST Amount</th>
                      <th className="w-16 px-1 border-2">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="w-4 p-2 align-top border-2">
                          {row.sno}.
                        </td>
                        <td className="p-1 align-top border-2 w-44 ">
                          <textarea
                            type="text"
                            value={row.description}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "description",
                                e.target.value
                              )
                            }
                            className="w-full focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="w-4 p-2 align-top border-2">
                          <input
                            type="text"
                            value={row.qty ?? 0}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "qty",
                                parseInt(e.target.value)
                              )
                            }
                            className="w-4 focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="w-16 p-2 align-top border-2 ">
                          <input
                            type="text"
                            value={`₹${row.rate.toFixed(2) ?? 0}`}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "rate",
                                parseFloat(e.target.value.replace("₹", ""))
                              )
                            }
                            className="w-16 focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="p-2 align-top border-2 w-7 ">
                          <input
                            type="text"
                            value={`${row.cgst ?? 0}%`}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "cgst",
                                parseInt(e.target.value.replace("%", ""))
                              )
                            }
                            className="w-7 focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="p-2 align-top border-2 w-7 ">
                          <input
                            type="text"
                            value={`${row.sgst ?? 0}%`}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "sgst",
                                parseInt(e.target.value.replace("%", ""))
                              )
                            }
                            className="w-7 focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="w-8 p-2 align-top border-2 ">
                          <input
                            type="text"
                            value={`₹${
                              (
                                (row.rate * row.qty * (row.cgst + row.sgst)) /
                                100
                              ).toFixed(2) ?? 0
                            }`}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                "totalGSTAmount",
                                parseFloat(e.target.value.replace("₹", ""))
                              )
                            }
                            className="w-16 focus:outline-none focus:shadow-outline"
                          />
                        </td>
                        <td className="w-16 p-2 align-top border-2 ">
                          {`₹${
                            (
                              row.rate * row.qty +
                              (row.rate * row.qty * (row.cgst + row.sgst)) / 100
                            ).toFixed(2) ?? 0
                          }`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex flex-row items-center justify-between px-2 no-print">
                  <div>
                    <button onClick={handleAddRow}>
                      <FaPlus className="w-3 h-3" />
                    </button>
                    <button
                      onClick={handleRemoveRow}
                      disabled={tableData.length === 1}
                    >
                      <FaMinus className="w-3 h-3 ml-4" />
                    </button>
                  </div>
                  <div>
                    <button onClick={handleCalculate}>
                      <MdCalculate className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between p-2 mt-1 border-2">
                <div className="w-1/2">
                  <div className="flex flex-col justify-end h-full">
                    <h1 className="text-xs font-bold uppercase">
                      Amount in Words
                    </h1>
                    <h1 className="text-xs font-semibold text-red-600">
                      {toWords.convert(grandTotal)}
                    </h1>
                  </div>
                </div>
                <div className="w-[1/2] flex flex-col justify-end">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-xs font-bold uppercase">Total</h1>
                    <input
                      type="text"
                      value={`₹${total ?? ""}`}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value.replace("₹", "")))
                      }
                      className="w-auto text-sm font-bold text-right uppercase focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-xs font-bold uppercase">
                      Advance Payment
                    </h1>
                    <input
                      type="text"
                      value={`₹${advancePayment ?? ""}`}
                      onChange={(e) =>
                        setAdvancePayment(
                          parseFloat(e.target.value.replace("₹", "")).toFixed(2)
                        )
                      }
                      className="w-auto text-sm font-bold text-right uppercase focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div className="w-full h-[1px] my-[0.5px] bg-black/20" />
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-xs font-bold text-red-600 uppercase">
                      Net Payable
                    </h1>
                    <input
                      type="text"
                      value={`₹${grandTotal ?? ""}`}
                      onChange={(e) =>
                        setGrandTotal(
                          parseFloat(e.target.value.replace("₹", "")).toFixed(2)
                        )
                      }
                      className="w-auto text-sm font-bold text-right text-red-600 uppercase focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              </div>
              <div className="p-2 mt-1 border-2 ">
                <h1 className="text-xs font-bold uppercase">Accounts</h1>
                <div className="flex flex-row justify-evenly">
                  {firmName === "jk_solar_energy"
                    ? jkse_accounts.map((account) => (
                        <BankAccount
                          key={account.accountNumber}
                          bankName={account.bankName}
                          accountNumber={account.accountNumber}
                          ifscCode={account.ifscCode}
                          logo={account.logo}
                        />
                      ))
                    : jkss_accounts.map((account) => (
                        <BankAccount
                          key={account.id}
                          bankName={account.bankName}
                          accountNumber={account.accountNumber}
                          ifscCode={account.ifscCode}
                          logo={account.logo}
                        />
                      ))}
                </div>
              </div>
              <div className="p-2 mt-1 border-2 ">
                <h1 className="text-xs font-bold uppercase">
                  Terms & Conditions*
                </h1>
                <div className="flex flex-row items-stretch justify-between">
                  <div className="flex flex-col justify-between">
                    <ol className="px-4 text-xs font-semibold list-decimal">
                      <li>Goods Once Sold Cannot Be taken Back.</li>
                      <li>
                        Warranty only applicable according to company Norms.
                      </li>
                      <li>
                        Interest@18% will be charged if the payment is not made
                        in the stipulated time.
                      </li>
                      <li>Subject to Anantnag Jurisdiction only.</li>
                      <li>E.& O.E</li>
                    </ol>
                    <div className="p-2 text-xl italic font-semibold text-center text-black/80">
                      Now say goodbye to power cuts.
                    </div>
                  </div>
                  <div className="p-1 border-2  w-[300px] h-full flex flex-col">
                    <div>
                      <h1 className="text-xs font-semibold">
                        AUTHORIZED SIGNATORY :
                      </h1>
                      <div className="h-[40px]" />
                    </div>
                    <div className="h-[1px] my-1 bg-black/20 w-full" />
                    <div>
                      <div className="h-[40px]" />
                      <h1 className="px-2 text-xs font-bold text-end">
                        {firmName === "jk_solar_energy"
                          ? "For JK SOLAR ENERGY"
                          : "For JK SALE & SERVICE AGENCY"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminLayout>
    </ProtectedRoute>
  );
};
