import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { create } from "zustand";
import { db } from "../firebase/firebaseConfig";

const useCustomersStore = create((set, get) => ({
  docs: [],
  querySnapshot: null,
  loading: true,
  cached: false,
  setLoading: (value) => set({ loading: value }),
  setCached: (value) => set({ cached: value }),
  fetchCustomers: async () => {
    try {
      const cached = get().cached;
      if (!cached) {
        const ordersQuery = query(
          collection(db, "customers"),
          orderBy("createdAt", "desc")
          //limit(10)
        );

        const docs = [];
        const querySnapshot = await getDocs(ordersQuery);
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });

        set({ docs, querySnapshot, loading: false, cached: true, error: null });
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      set({ loading: false });
      set({ error });
    }
  },
}));

export default useCustomersStore;
