import { useState } from "react";

const DashboardCard = ({ title, num, icon: Icon, hide = false }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex items-center p-3 space-x-2 bg-white rounded-lg shadow-md">
      <div>
        <Icon size={30} className="hidden mr-2 md:block" />
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-sm font-semibold text-gray-700">{title}</h3>
        {!hide ? (
          <p className="text-lg font-bold text-center text-[#1E3A8A]">{num}</p>
        ) : (
          <p className="text-lg font-bold text-center text-[#1E3A8A]">
            <button onClick={() => setShow(!show)}>
              {show ? num : "********"}
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
