import React, { useEffect, useState, useMemo, useCallback } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { PuffLoader } from "react-spinners";
import ProtectedRoute from "../../components/ProtectedRoute";
import { BsPersonFillAdd } from "react-icons/bs";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/AdminLayout";
import AdvisorTable from "../../components/AdvisorTable";
import LayoutHeader from "../../components/LayoutHeader";
import { toastOptions } from "../../utils/helpers";
import { toast } from "react-toastify";

export default function Advisors() {
  const [loading, setLoading] = useState(true);
  const [advisors, setAdvisors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const updateAdvisors = useCallback((newAdvisors) => {
    setAdvisors(newAdvisors);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    const advisorsCol = collection(db, "advisor_list");
    const unsubscribe = onSnapshot(
      query(advisorsCol, orderBy("advisor_code", "asc")),
      (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        updateAdvisors(docs);
        setLoading(false);
      },
      (error) => {
        if (error?.code === "resource-exhausted") {
          toast.error("Quota exceeded", toastOptions);
        } else {
          toast.error("Something went wrong", toastOptions);
        }
        setLoading(false);
      }
    );
    return unsubscribe;
  }, [updateAdvisors]);

  const filteredAdvisors = useMemo(
    () =>
      advisors.filter((advisor) =>
        advisor.advisor_name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [advisors, searchQuery]
  );

  return (
    <>
      <ProtectedRoute>
        <AdminLayout>
          <div className="h-full overflow-x-auto overflow-y-auto ">
            <LayoutHeader title={"Advisors"} />
            <div className="mx-4 header">
              <div className="flex justify-between py-2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search by Advisor name"
                  className="px-2 py-2 text-gray-600 border rounded-md w-45 sm:w-96"
                />
                <Link
                  to="/admin/add-advisor"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <BsPersonFillAdd className="w-5 h-5 mr-2" />
                  <span className="hidden md:block">Add Advisor</span>
                </Link>
              </div>
            </div>

            {loading && (
              <div className="flex justify-center w-full py-8">
                <PuffLoader size={60} />
              </div>
            )}

            {!loading && <AdvisorTable advisors={filteredAdvisors} />}
          </div>
        </AdminLayout>
      </ProtectedRoute>
    </>
  );
}
