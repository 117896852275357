function parseFirebaseTimestamp(timestamp) {
  if (timestamp === null) return null;
  const date = new Date(timestamp.seconds * 1000);
  const formattedDate = date.toLocaleString();
  return formattedDate;
}

const toastOptions = {
  position: "bottom-center",
  theme: "light",
  autoClose: 1500,
};

const formatAmountToInr = (amount) => {
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return "Invalid Input";
  }

  // Format the number to Indian currency format
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(numericAmount);

  return formattedAmount;
};

export { parseFirebaseTimestamp, toastOptions, formatAmountToInr };
