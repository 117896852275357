import AdminLayout from "../../components/AdminLayout";
import ProtectedRoute from "../../components/ProtectedRoute";
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  query,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toastOptions } from "../../utils/helpers";
import LayoutHeader from "../../components/LayoutHeader";

const defaultPhotoUrl =
  "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";

export default function AddEmployee() {
  const navigate = useNavigate();

  const [employeeName, setEmployeeName] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address, setAddress] = useState("");
  const [idIssued, setIdIssued] = useState("");
  const [isActive, setIsActive] = useState("");

  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [empCode, setEmpCode] = useState(0);

  const [photoUrl, setPhotoUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    const getEmpCode = async () => {
      const q = query(collection(db, "codes"));
      const querySnapshot = await getDocs(q);
      setEmpCode(querySnapshot.docs[1].data().prefix);
    };
    getEmpCode();
  }, []);

  useEffect(() => {
    setPhotoUrl(defaultPhotoUrl);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (empCode !== 0) {
      setAddBtnClicked(true);
      const employeeCode = (empCode + 1).toString() + "JKSE";
      const data = {
        employee_code: employeeCode,
        employee_name: employeeName,
        position: position,
        phone_no: phoneNo,
        email_id: emailId,
        address: address,
        id_issued: idIssued,
        is_active: isActive,
        photoUrl: photoUrl,
      };
      try {
        const newDocRef = doc(collection(db, "employee_list"));
        await setDoc(newDocRef, data);
        const updateRef = doc(db, "codes", "last_employee_code");
        await updateDoc(updateRef, { prefix: empCode + 1 });

        // Add employee code to employee_codes collection
        const empCodeData = {
          employee_code: employeeCode,
          employee_name: employeeName,
          photoUrl: defaultPhotoUrl,
          branch: "JKSE Anantnag",
          active: isActive,
        };
        const empCodeRef = doc(collection(db, "employee_codes"));
        await setDoc(empCodeRef, empCodeData);

        setAddBtnClicked(false);
        toast.success("Employee added successfully!", toastOptions);
        navigate("/admin/employees");
      } catch (error) {
        setAddBtnClicked(false);
        console.error(error);
      }
    }
  };

  const storage = getStorage();

  const handleImageUpload = (e) => {
    try {
      const file = e.target.files[0];
      setImageUploading(true);
      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
      } else {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000);
        const storageRef = ref(storage, `/employees/${timestamp}-${randomNum}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => console.error(err),
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask?.snapshot?.ref);

            setPhotoUrl(downloadUrl);
            setImageUploading(false);
          }
        );
      }
    } catch (error) {
      setPhotoUrl(defaultPhotoUrl);
      console.error(error);
    }
  };

  return (
    <>
      <ProtectedRoute>
        <AdminLayout>
          <div className="h-full mx-2 overflow-x-auto overflow-y-auto ">
            <LayoutHeader title={"Add Employee"} />

            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/2 md:px-4">
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="employeeName"
                    >
                      Employee Name:
                    </label>
                    <input
                      type="text"
                      id="employeeName"
                      placeholder="Enter Employee Name"
                      value={employeeName ?? ""}
                      onChange={(e) => setEmployeeName(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="phoneNo"
                    >
                      Phone Number:
                    </label>
                    <input
                      type="text"
                      id="phoneNo"
                      placeholder="Enter Phone Number"
                      maxLength={10}
                      value={phoneNo ?? ""}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full space-x-8 md:w-1/3 md:px-4 ">
                  {photoUrl && (
                    <img
                      src={photoUrl}
                      alt="user"
                      className="w-20 mt-4 rounded-lg md:w-40 "
                    />
                  )}
                  {!imageUploading ? (
                    <div className="text-center">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        id="image-upload"
                      />
                      <label
                        htmlFor="image-upload"
                        className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                      >
                        Upload Image
                      </label>
                    </div>
                  ) : (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block mb-2 font-bold text-gray-700"
                  htmlFor="position"
                >
                  Position:
                </label>
                <select
                  id="position"
                  value={position ?? ""}
                  onChange={(e) => setPosition(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select Position</option>
                  <option value="Sales Officer">Sales Officer</option>
                  <option value="Branch Manager">Branch Manager</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block mb-2 font-bold text-gray-700"
                  htmlFor="address"
                >
                  Address:
                </label>
                <input
                  type="text"
                  id="address"
                  placeholder="Enter Address"
                  value={address ?? ""}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="mb-4">
                <label
                  className="block mb-2 font-bold text-gray-700"
                  htmlFor="emailId"
                >
                  Email ID:
                </label>
                <input
                  type="text"
                  id="emailId"
                  placeholder="Enter Email ID"
                  value={emailId ?? ""}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="inline-flex items-center font-bold text-gray-700">
                  <input
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 form-checkbox"
                    checked={idIssued ?? false}
                    onChange={(e) => setIdIssued(e.target.checked)}
                  />
                  <span className="ml-2">ID Issued</span>
                </label>
              </div>
              <div className="mb-4">
                <label className="inline-flex items-center font-bold text-gray-700">
                  <input
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 form-checkbox"
                    checked={isActive ?? false}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                  <span className="ml-2">Is Active</span>
                </label>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="px-4 py-2 text-white bg-blue-900 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                  {addBtnClicked && (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                  {!addBtnClicked && "Add Employee"}
                </button>
              </div>
            </div>
          </div>
        </AdminLayout>
      </ProtectedRoute>
    </>
  );
}
