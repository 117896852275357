import React from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { useState, useEffect } from "react";
import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import PuffLoader from "react-spinners/PuffLoader";
import UserCard from "../../components/UserCard";
import AddUserModal from "../../components/AddUserModal";
import LayoutHeader from "../../components/LayoutHeader";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getUsersFromFirestore = async () => {
      const usersCollection = collection(db, "users");
      const querySnapshot = await getDocs(usersCollection);

      const updatedUsers = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        return {
          id: doc.id,
          displayName: user.displayName,
          email: user.email,
          photoUrl: user.photoUrl,
          lastLogin: user.lastLogin,
          isVerified: user.emailVerified,
          active: user.active,
          admin: user.admin,
          uid: user.uid,
        };
      });

      setUsers(updatedUsers);
      setIsLoading(false);
    };

    getUsersFromFirestore();
  }, [isModalOpen]);

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-x-auto overflow-y-auto ">
          <div className=" header">
            <LayoutHeader title={"Users"} />

            {isLoading && (
              <div className="flex justify-center w-full my-10">
                <PuffLoader size={60} />
              </div>
            )}
            <AddUserModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
            <div className="mx-4 my-4 space-y-4 header">
              {users.map((user) => (
                <UserCard
                  key={user.id}
                  name={user.displayName}
                  email={user.email}
                  photoUrl={user.photoUrl}
                  lastLogin={user.lastLogin}
                  isVerified={user.isVerified}
                  active={user.active}
                  admin={user.admin}
                  uid={user.id}
                />
              ))}
            </div>
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
