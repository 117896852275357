import React, { useMemo } from "react";
import { RxDashboard } from "react-icons/rx";
import { FiLogOut } from "react-icons/fi";
import { useContext, useState } from "react";
import { AuthContext } from "../utils/authContext";
import { Link, useLocation } from "react-router-dom";
import { FaPeopleArrows, FaSpinner } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";

export default function UserLayout({ children }) {
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleLogOut = useMemo(
    () => async () => {
      try {
        setLoading(true);
        await signOut();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [signOut]
  );

  return (
    <main className="flex min-h-screen fles-col bg-[#F6F7F9]">
      <div className="w-2/12 h-screen bg-blue-900 sidebar">
        <div className="flex-row items-center justify-between mt-4">
          <div className="flex items-center justify-center">
            <Link to="/user/dashboard">
              <img
                height={64}
                width={64}
                src="/assets/logo.svg"
                alt="logo"
                className="w-10 h-10 lg:w-32 lg:h-32"
              />
            </Link>
          </div>
          <ul className="py-10 space-y-1 ">
            <li
              className={
                location.pathname === "/user/dashboard"
                  ? "px-2 py-2 mx-1 rounded-md bg-blue-950 "
                  : "px-2 py-2 mx-1 bg-blue-900 rounded-md hover:bg-blue-950"
              }
            >
              <Link
                to="/user/dashboard"
                className="flex items-center space-x-2 text-white"
              >
                <RxDashboard />
                <h1 className="hidden font-semibold md:block">Dashboard</h1>
              </Link>
            </li>

            <li
              className={
                location.pathname === "/user/customers"
                  ? "px-2 py-2 mx-1 rounded-md bg-blue-950 "
                  : "px-2 py-2 mx-1 bg-blue-900 rounded-md hover:bg-blue-950"
              }
            >
              <Link
                to="/user/customers"
                className="flex items-center space-x-2 text-white"
              >
                <FaPeopleArrows />
                <h1 className="hidden font-semibold md:block">Customers</h1>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/user/orders"
                  ? "px-2 py-2 mx-1 rounded-md bg-blue-950 "
                  : "px-2 py-2 mx-1 bg-blue-900 rounded-md hover:bg-blue-950"
              }
            >
              <Link
                to="/user/orders"
                className="flex items-center space-x-2 text-white"
              >
                <MdShoppingCart />
                <h1 className="hidden font-semibold md:block">Orders</h1>
              </Link>
            </li>
          </ul>
        </div>
        <div className="fixed px-2 py-2 mx-1 bg-blue-900 rounded-md bottom-4 hover:bg-blue-950">
          <button onClick={handleLogOut}>
            {loading ? (
              <FaSpinner className="mx-2 animate-spin" size={20} />
            ) : (
              <div className="flex items-center space-x-2 text-white ">
                <FiLogOut />
                <h1 className="hidden font-semibold md:block">Log Out </h1>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="w-10/12 h-screen main ">{children}</div>
    </main>
  );
}
