import React from "react";
import AdvisorModal from "./AdvisorModal";
import { useState } from "react";

export default function AdvisorTable({ advisors }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleOnClick = (advisor) => {
    setData(advisor);
    setIsModalOpen(true);
  };

  return (
    <>
      {data && (
        <AdvisorModal
          key={data.advisor_code}
          id={data}
          data={data}
          setData={setData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Email Address
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Address
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              ID Issued
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {advisors.map((advisor) => (
            <tr key={advisor.id}>
              <td
                className={`px-6 py-4 text-sm font-medium cursor-pointer ${
                  advisor.is_active ? "text-green-600" : "text-red-600"
                } whitespace-nowrap hover:underline `}
                onClick={() => handleOnClick(advisor)}
              >
                {advisor.advisor_code}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {advisor.advisor_name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {advisor.phone_no ? advisor.phone_no : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {advisor.email_id ? advisor.email_id : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {advisor.address
                  ? advisor.address.length > 33
                    ? advisor.address.substring(0, 30) + "..."
                    : advisor.address
                  : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {advisor.id_issued ? "Yes" : "No"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
