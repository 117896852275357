import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { FaDownload, FaSpinner } from "react-icons/fa";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toastOptions } from "../../utils/helpers";
import { toast } from "react-toastify";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { IoIosArrowBack } from "react-icons/io";

export default function EditOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const storage = getStorage();

  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [pmsgyCustomer, setPmsgyCustomer] = useState("no");
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhoneNo, setCustomerPhoneNo] = useState("");
  const [customerAlternatePhoneNo, setCustomerAlternatePhoneNo] = useState("");
  const [customerAadharNo, setCustomerAadharNo] = useState("");
  const [customerPanNo, setCustomerPanNo] = useState("");
  const [customerParentage, setCustomerParentage] = useState("");
  const [customerBankName, setCustomerBankName] = useState("");
  const [customerBankAccountNo, setCustomerBankAccountNo] = useState("");
  const [customerBankIfscCode, setCustomerBankIfscCode] = useState("");
  const [customerPhotoUrl, setCustomerPhotoUrl] = useState("");
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerQualification, setCustomerQualification] = useState("");

  const [guarantor, setGuarantor] = useState(false);
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPanNo, setGuarantorPanNo] = useState("");
  const [guarantorAadharNo, setGuarantorAadharNo] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [guarantorBankAccountNo, setGuarantorBankAccountNo] = useState("");
  const [guarantorBankName, setGuarantorBankName] = useState("");
  const [guarantorIfscCode, setGuarantorIfscCode] = useState("");
  const [guarantorParentage, setGuarantorParentage] = useState("");
  const [guarantorPhoneNo, setGuarantorPhoneNo] = useState("");
  const [guarantorPhotoUrl, setGuarantorPhotoUrl] = useState("");
  const [guarantorOccupation, setGuarantorOccupation] = useState("");
  const [guarantorQualification, setGuarantorQualification] = useState("");

  const [finance, setFinance] = useState(false);
  const [financedBy, setFinancedBy] = useState("");

  const [orderDetails, setOrderDetails] = useState("");
  const [orderCost, setOrderCost] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [orderDate, setOrderDate] = useState(new Date());

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [downpayment, setDownpayment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeBankName, setChequeBankName] = useState("");

  const [customerImageUploading, setCustomerImageUploading] = useState(false);
  const [guarantorImageUploading, setGuarantorImageUploading] = useState(false);

  const [electricityBillUrl, setElectricityBillUrl] = useState("");
  const [bankPassbookUrl, setBankPassbookUrl] = useState("");

  const [electricityBillUploading, setElectricityBillUploading] =
    useState(false);
  const [bankPassbookUploading, setBankPassbookUploading] = useState(false);

  const [performaBillNo, setPerformaBillNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [commission, setCommission] = useState("");

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [updateBtnClicked, setUpdateBtnClicked] = useState(false);

  const getOrderDetails = async () => {
    if (id === undefined) return;
    try {
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setFirmName(docSnap.data().firm_name);
        setAdvisorCode(docSnap.data().advisor_code);
        setAdvisorName(docSnap.data().advisor_name);
        setCustomerName(docSnap.data().customer_name);
        setCustomerAddress(docSnap.data().customer_address);
        setCustomerPhoneNo(docSnap.data().customer_phone_no);
        setCustomerAlternatePhoneNo(docSnap.data().customer_alternate_phone_no);
        setCustomerAadharNo(docSnap.data().customer_aadhar_no);
        setCustomerPanNo(docSnap.data().customer_pan_no);
        setCustomerParentage(docSnap.data().customer_parentage);
        setCustomerBankName(docSnap.data().customer_bank_name);
        setCustomerBankAccountNo(docSnap.data().customer_bank_account_no);
        setCustomerBankIfscCode(docSnap.data().customer_bank_ifsc_code);
        setCustomerPhotoUrl(docSnap.data().customer_photo_url);
        setCustomerOccupation(docSnap.data().customer_occupation);
        setCustomerQualification(docSnap.data().customer_qualification);
        setGuarantor(docSnap.data().guarantor);
        setGuarantorName(docSnap.data().guarantor_name);
        setGuarantorPanNo(docSnap.data().guarantor_pan_no);
        setGuarantorAadharNo(docSnap.data().guarantor_aadhar_no);
        setGuarantorAddress(docSnap.data().guarantor_address);
        setGuarantorBankAccountNo(docSnap.data().guarantor_bank_account_no);
        setGuarantorBankName(docSnap.data().guarantor_bank_name);
        setGuarantorIfscCode(docSnap.data().guarantor_bank_ifsc_code);
        setGuarantorParentage(docSnap.data().guarantor_parentage);
        setGuarantorPhoneNo(docSnap.data().guarantor_phone_no);
        setGuarantorPhotoUrl(docSnap.data().guarantor_photo_url);
        setGuarantorOccupation(docSnap.data().guarantor_occupation);
        setGuarantorQualification(docSnap.data().guarantor_qualification);
        setFinance(docSnap.data().finance);
        setFinancedBy(docSnap.data().financed_by);
        setOrderDetails(docSnap.data().order_details);
        setOrderCost(docSnap.data().order_cost);
        setOrderNotes(docSnap.data().order_notes);
        setOrderDate(docSnap.data().order_date);
        setPaymentMethod(docSnap.data().payment_method);
        setDownpayment(docSnap.data().downpayment);
        setTransactionId(docSnap.data().transaction_id);
        setChequeNumber(docSnap.data().chequeNumber);
        setChequeDate(docSnap.data().chequeDate);
        setChequeBankName(docSnap.data().chequeBankName);
        setPerformaBillNo(docSnap.data().performaBillNo);
        setChallanNo(docSnap.data().challanNo);
        setCommission(docSnap.data().commission);
        setElectricityBillUrl(docSnap.data()?.electricityBillUrl ?? "");
        setBankPassbookUrl(docSnap.data()?.bankPassbookUrl ?? "");
        setPmsgyCustomer(docSnap.data()?.pmsgyCustomer ?? "no");
      } else {
        // doc.data() will be undefined in this case
        toast.error("No such document exists!", toastOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllEmployees = async () => {
    const employees = [];
    const employeesRef = collection(db, "employee_codes");
    const employeesSnapshot = await getDocs(employeesRef);
    employeesSnapshot.forEach((doc) => {
      employees.push({
        employee_name: doc.data().employee_name,
        employee_code: doc.data().employee_code,
      });
    });
    // Others (if employee is not applicable)
    employees.push({
      employee_name: "Others",
      employee_code: "Others",
    });
    setEmployees(employees);
  };

  useEffect(() => {
    // fetch order details by id params from firestore
    getOrderDetails();
    // get all order details
    getAllEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageUpload = (e, setPhotoUrl, setImageUploading) => {
    try {
      const file = e.target.files[0];
      setImageUploading(true);
      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
      } else {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000);
        const storageRef = ref(storage, `/orders/${timestamp}-${randomNum}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => console.error(err),
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask?.snapshot?.ref);

            setPhotoUrl(downloadUrl);
            setImageUploading(false);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCustomerImageUpload = (e) => {
    handleImageUpload(e, setCustomerPhotoUrl, setCustomerImageUploading);
  };

  const handleGuarantorImageUpload = (e) => {
    handleImageUpload(e, setGuarantorPhotoUrl, setGuarantorImageUploading);
  };

  const handleElectricityBillUpload = (e) => {
    handleImageUpload(e, setElectricityBillUrl, setElectricityBillUploading);
  };

  const handleBankPassbookUpload = (e) => {
    handleImageUpload(e, setBankPassbookUrl, setBankPassbookUploading);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateBtnClicked(true);
    const updatedData = {
      firm_name: firmName,
      advisor_code: advisorCode,
      advisor_name: advisorName,
      customer_name: customerName,
      customer_address: customerAddress,
      customer_phone_no: customerPhoneNo,
      customer_alternate_phone_no: customerAlternatePhoneNo,
      customer_aadhar_no: customerAadharNo,
      customer_pan_no: customerPanNo,
      customer_parentage: customerParentage,
      customer_bank_account_no: customerBankAccountNo,
      customer_bank_name: customerBankName,
      customer_bank_ifsc_code: customerBankIfscCode,
      customer_photo_url: customerPhotoUrl,
      customer_occupation: customerOccupation,
      customer_qualification: customerQualification,
      guarantor: guarantor,
      guarantor_name: guarantorName,
      guarantor_pan_no: guarantorPanNo,
      guarantor_aadhar_no: guarantorAadharNo,
      guarantor_address: guarantorAddress,
      guarantor_bank_account_no: guarantorBankAccountNo,
      guarantor_bank_name: guarantorBankName,
      guarantor_bank_ifsc_code: guarantorIfscCode,
      guarantor_parentage: guarantorParentage,
      guarantor_phone_no: guarantorPhoneNo,
      guarantor_photo_url: guarantorPhotoUrl,
      guarantor_occupation: guarantorOccupation,
      guarantor_qualification: guarantorQualification,
      finance: finance,
      financed_by: financedBy,
      order_details: orderDetails,
      order_date: orderDate,
      order_cost: orderCost,
      order_notes: orderNotes,
      payment_method: paymentMethod,
      downpayment: downpayment,
      transaction_id: transactionId,
      chequeNumber: chequeNumber,
      chequeDate: chequeDate,
      chequeBankName: chequeBankName,
      performaBillNo: performaBillNo,
      challanNo: challanNo,
      commission: commission,
      pmsgyCustomer: pmsgyCustomer,
      electricityBillUrl: electricityBillUrl,
      bankPassbookUrl: bankPassbookUrl,
    };

    try {
      const updateOrderRef = doc(db, "orders", id);
      await updateDoc(updateOrderRef, updatedData);

      const firmCode =
        firmName === "jk_solar_energy"
          ? "JKSE"
          : firmName === "saaslynx"
          ? "SSLX"
          : "JKSS";
      const customerDocRef = doc(
        db,
        "customers",
        `${firmCode}-CUS-${customerPhoneNo}`
      );
      await updateDoc(customerDocRef, {
        photoUrl: customerPhotoUrl,
      });

      toast.success("Order updated successfully!", toastOptions);
      setTimeout(() => {
        navigate(`/admin/orders`);
      }, 1500);
    } catch (error) {
      console.error(error);
      setUpdateBtnClicked(false);
      toast.error("Error updating order", toastOptions);
    }
  };

  const [isGoBackClicked, setIsGoBackClicked] = useState(false);

  const handleGoBack = () => {
    setIsGoBackClicked(true);
    setTimeout(() => {
      navigate(`/admin/orders`);
    }, 500);
  };

  const handleAdvisorNameChange = (e) => {
    const inputName = e.target.value;
    setAdvisorName(inputName);

    const filtered = employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(inputName.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  const handleEmployeeSelection = (selectedEmployee) => {
    setAdvisorName(selectedEmployee.employee_name);
    setAdvisorCode(selectedEmployee.employee_code);
    setFilteredEmployees([]); // Clear the suggestions
  };

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full mx-2 overflow-x-auto overflow-y-auto ">
          <div className="flex items-center space-x-4 btns">
            <div className="flex items-center justify-center p-1 m-2 bg-gray-500 rounded-full hover:bg-gray-600 hover:cursor-pointer ">
              <IoIosArrowBack
                size={26}
                className={`text-white transform ${
                  isGoBackClicked ? "rotate-180" : ""
                } transition duration-500 ease-in-out`}
                onClick={handleGoBack}
              />
            </div>
            <h1 className="py-2 mx-2 text-xl font-semibold text-gray-600">
              Edit Order
            </h1>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="firmName"
                  >
                    Firm Name*:
                  </label>

                  <span className="ml-2 font-bold">
                    {firmName === "jk_solar_energy"
                      ? "JK Solar Energy"
                      : firmName === "saaslynx"
                      ? "SaasLynx"
                      : "JK Sale and Service Agency"}
                  </span>
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="advisorCode"
                  >
                    Advisor Code:
                  </label>
                  <input
                    type="text"
                    id="advisorCode"
                    maxLength={8}
                    placeholder="Optional"
                    value={advisorCode ?? ""}
                    onChange={(e) =>
                      setAdvisorCode(e.target.value.toUpperCase())
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="pmsgyCustomer"
                  >
                    PMSGY Customer*:
                  </label>
                  <span className="ml-2 font-bold">
                    {pmsgyCustomer === "yes" ? "YES" : "NO"}
                  </span>
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="advisorName"
                  >
                    Advisor Name:
                  </label>
                  <input
                    type="text"
                    id="advisorName"
                    placeholder="Optional"
                    value={advisorName ?? ""}
                    onChange={handleAdvisorNameChange}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                  {filteredEmployees.length > 0 && (
                    <ul className=" w-auto h-[20rem] mt-2 overflow-y-scroll bg-white border border-gray-300 rounded-md">
                      {filteredEmployees.map((employee) => (
                        <li
                          key={employee.employee_code}
                          className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleEmployeeSelection(employee)}
                        >
                          {employee.employee_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderDate"
                  >
                    Order Date*:
                  </label>
                  <input
                    type="date"
                    id="orderDate"
                    required
                    value={orderDate ?? ""}
                    onChange={(e) => setOrderDate(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/2 md:px-4">
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerName"
                    >
                      Customer Name*:
                    </label>
                    <input
                      type="text"
                      id="customerName"
                      required
                      placeholder="Enter Customer Name"
                      value={customerName ?? ""}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerParentage"
                    >
                      Customer Parentage*:
                    </label>
                    <input
                      type="text"
                      id="customerParentage"
                      required
                      placeholder="Enter Customer Parentage"
                      value={customerParentage ?? ""}
                      onChange={(e) => setCustomerParentage(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerAddress"
                    >
                      Customer Address*:
                    </label>
                    <input
                      type="text"
                      id="customerAddress"
                      required
                      placeholder="Enter Customer Address"
                      value={customerAddress ?? ""}
                      onChange={(e) => setCustomerAddress(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerPhoneNo"
                    >
                      Customer Phone No*:
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      id="customerPhoneNo"
                      required
                      placeholder="Enter Customer Phone Number"
                      value={customerPhoneNo ?? ""}
                      onChange={(e) => setCustomerPhoneNo(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full space-x-8 md:w-1/3 md:px-4 ">
                  {customerPhotoUrl && (
                    <img
                      src={customerPhotoUrl}
                      alt="customer"
                      className="w-20 mt-4 rounded-lg md:w-40 "
                    />
                  )}
                  {!customerImageUploading ? (
                    <div className="text-center">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleCustomerImageUpload}
                        className="hidden"
                        id="customer-image-upload"
                      />
                      <label
                        htmlFor="customer-image-upload"
                        className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                      >
                        Upload Image
                      </label>
                    </div>
                  ) : (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerAlternatePhoneNo"
                  >
                    Customer Alternate Phone No:
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    id="customerAlternatePhoneNo"
                    placeholder="Optional"
                    value={customerAlternatePhoneNo ?? ""}
                    onChange={(e) =>
                      setCustomerAlternatePhoneNo(e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerQualification"
                  >
                    Customer Qualification*:
                  </label>
                  <input
                    type="text"
                    id="customerQualification"
                    placeholder="Enter Customer Qualification"
                    required
                    value={customerQualification ?? ""}
                    onChange={(e) => setCustomerQualification(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerOccupation"
                  >
                    Customer Occupation*:
                  </label>
                  <input
                    type="text"
                    id="customerOccupation"
                    placeholder="Enter Customer Occupation"
                    required
                    value={customerOccupation ?? ""}
                    onChange={(e) => setCustomerOccupation(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerPanNo"
                  >
                    Customer PAN no:
                  </label>
                  <input
                    type="text"
                    id="customerPanNo"
                    placeholder="Optional"
                    value={customerPanNo ?? ""}
                    onChange={(e) => setCustomerPanNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerAadharNo"
                  >
                    Customer Aadhar No:
                  </label>
                  <input
                    type="text"
                    id="customerAadharNo"
                    placeholder="Optional"
                    value={customerAadharNo ?? ""}
                    onChange={(e) => setCustomerAadharNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankAccountNo"
                  >
                    Customer Bank Account No:
                  </label>
                  <input
                    type="text"
                    id="customerBankAccountNo"
                    placeholder="Optional"
                    value={customerBankAccountNo ?? ""}
                    onChange={(e) => setCustomerBankAccountNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankName"
                  >
                    Customer Bank Name:
                  </label>
                  <input
                    type="text"
                    id="customerBankName"
                    placeholder="Optional"
                    value={customerBankName ?? ""}
                    onChange={(e) => setCustomerBankName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankIfscCode"
                  >
                    Customer Bank IFSC Code:
                  </label>
                  <input
                    type="text"
                    id="customerBankIfscCode"
                    placeholder="Optional"
                    value={customerBankIfscCode ?? ""}
                    onChange={(e) => setCustomerBankIfscCode(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="pt-4 mb-4">
                <label className="inline-flex items-center font-bold text-gray-700">
                  <input
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 form-checkbox"
                    checked={guarantor ?? false}
                    onChange={(e) => setGuarantor(e.target.checked)}
                  />
                  <span className="ml-2">Guarantor</span>
                </label>
              </div>
              {guarantor && (
                <>
                  <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                    <div className="w-full md:w-1/2 md:px-4">
                      <div className="mb-4">
                        <label
                          className="block mb-2 font-bold text-gray-700"
                          htmlFor="guarantorName"
                        >
                          Guarantor Name:
                        </label>
                        <input
                          type="text"
                          id="guarantorName"
                          placeholder="Enter Guarantor Name"
                          value={guarantorName ?? ""}
                          onChange={(e) => setGuarantorName(e.target.value)}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="mb-4 ">
                        <label
                          className="block mb-2 font-bold text-gray-700"
                          htmlFor="guarantorParentage"
                        >
                          Guarantor Parentage:
                        </label>
                        <input
                          type="text"
                          id="guarantorParentage"
                          placeholder="Enter Guarantor Parentage"
                          value={guarantorParentage ?? ""}
                          onChange={(e) =>
                            setGuarantorParentage(e.target.value)
                          }
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full space-x-8 md:w-1/3 md:px-4 ">
                      {guarantorPhotoUrl && (
                        <img
                          src={guarantorPhotoUrl}
                          alt="guarantor"
                          className="w-20 mt-4 rounded-lg md:w-40 "
                        />
                      )}
                      {!guarantorImageUploading ? (
                        <div className="text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleGuarantorImageUpload}
                            className="hidden"
                            id="guarantor-image-upload"
                          />
                          <label
                            htmlFor="guarantor-image-upload"
                            className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                          >
                            Upload Image
                          </label>
                        </div>
                      ) : (
                        <FaSpinner className="mx-2 animate-spin" size={20} />
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorAddress"
                      >
                        Guarantor Address:
                      </label>
                      <input
                        type="text"
                        id="guarantorAddress"
                        placeholder="Enter Customer Address"
                        value={guarantorAddress ?? ""}
                        onChange={(e) => setGuarantorAddress(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorPhoneNo"
                      >
                        Guarantor Phone No:
                      </label>
                      <input
                        type="text"
                        id="guarantorPhoneNo"
                        placeholder="Enter Guarantor Phone Number"
                        value={guarantorPhoneNo ?? ""}
                        onChange={(e) => setGuarantorPhoneNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorOccupation"
                      >
                        Guarantor Occupation:
                      </label>
                      <input
                        type="text"
                        id="guarantorOccupation"
                        placeholder="Enter Guarantor Occupation"
                        required
                        value={guarantorOccupation ?? ""}
                        onChange={(e) => setGuarantorOccupation(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorAadharNo"
                      >
                        Guarantor Aadhar No:
                      </label>
                      <input
                        type="text"
                        id="guarantorAadharNo"
                        placeholder="Enter Guarantor Aadhar No"
                        value={guarantorAadharNo ?? ""}
                        onChange={(e) => setGuarantorAadharNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorQualification"
                      >
                        Guarantor Qualification:
                      </label>
                      <input
                        type="text"
                        id="guarantorQualification"
                        placeholder="Enter Guarantor Qualification"
                        required
                        value={guarantorQualification ?? ""}
                        onChange={(e) =>
                          setGuarantorQualification(e.target.value)
                        }
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorPanNo"
                      >
                        Guarantor PAN no:
                      </label>
                      <input
                        type="text"
                        id="guarantorPanNo"
                        placeholder="Enter Guarantor PAN no"
                        value={guarantorPanNo ?? ""}
                        onChange={(e) => setGuarantorPanNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorBankAccountNo"
                      >
                        Guarantor Bank Account No:
                      </label>
                      <input
                        type="text"
                        id="guarantorBankAccountNo"
                        placeholder="Enter Guarantor Bank Account No"
                        value={guarantorBankAccountNo ?? ""}
                        onChange={(e) =>
                          setGuarantorBankAccountNo(e.target.value)
                        }
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorBankName"
                      >
                        Guarantor Bank Name:
                      </label>
                      <input
                        type="text"
                        id="guarantorBankName"
                        placeholder="Enter Guarantor Bank Name"
                        value={guarantorBankName ?? ""}
                        onChange={(e) => setGuarantorBankName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorIfscCode"
                      >
                        Guarantor Bank IFSC Code:
                      </label>
                      <input
                        type="text"
                        id="guarantorIfscCode"
                        placeholder="Enter Guarantor Bank IFSC Code"
                        value={guarantorIfscCode ?? ""}
                        onChange={(e) => setGuarantorIfscCode(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="grid grid-cols-1 gap-2 md:mt-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-2 md:mb-4 md:w-1/3 md:px-4">
                  <label className="inline-flex items-center font-bold text-gray-700">
                    <input
                      type="checkbox"
                      className="w-5 h-5 text-blue-600 form-checkbox"
                      checked={finance ?? false}
                      onChange={(e) => setFinance(e.target.checked)}
                    />
                    <span className="ml-2">Finance</span>
                  </label>
                </div>
                {finance && (
                  <div className="mb-4 md:w-1/2">
                    <select
                      id="position"
                      value={financedBy ?? ""}
                      onChange={(e) => setFinancedBy(e.target.value)}
                      className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select Finance Partner</option>
                      <option value="j_and_k_bank">J&K Bank</option>
                      <option value="sbi_bank">SBI Bank</option>
                      <option value="hdfc_bank">HDFC Bank</option>
                      <option value="bajaj_finserv">Bajaj Finserv</option>
                      <option value="samsung_finance">Samsung Finance</option>
                    </select>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-4 mb-4 md:mt-0 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderDetails"
                  >
                    Order Details*:
                  </label>
                  <textarea
                    type="text"
                    id="orderDetails"
                    required
                    rows={4}
                    placeholder="Enter Order Details"
                    value={orderDetails ?? ""}
                    onChange={(e) => setOrderDetails(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderCost"
                  >
                    Order Cost*:
                  </label>
                  <input
                    type="number"
                    id="orderCost"
                    required
                    placeholder="Enter Order Cost"
                    value={orderCost ?? ""}
                    onChange={(e) => setOrderCost(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderNotes"
                  >
                    Order Notes:
                  </label>
                  <textarea
                    type="text"
                    id="orderNotes"
                    rows={3}
                    placeholder="Enter Order Notes (Optional)"
                    value={orderNotes ?? ""}
                    onChange={(e) => setOrderNotes(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-4 mb-4 md:mt-0 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="paymentMethod"
                  >
                    Payment Method*:
                  </label>
                  <select
                    id="paymentMethod"
                    value={paymentMethod ?? ""}
                    required
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Payment Method</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="card">Card</option>
                    <option value="upi">UPI</option>
                  </select>
                </div>

                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="downpayment"
                  >
                    Downpayment:
                  </label>
                  <input
                    type="number"
                    id="downpayment"
                    placeholder="Optional"
                    value={downpayment ?? ""}
                    onChange={(e) => setDownpayment(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                {(paymentMethod === "card" || paymentMethod === "upi") && (
                  <div className="w-full md:w-1/2 md:px-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="transactionId"
                    >
                      Transaction Id:
                    </label>
                    <input
                      type="text"
                      id="transactionId"
                      placeholder="Enter Transaction Id"
                      value={transactionId ?? ""}
                      onChange={(e) => setTransactionId(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                )}
                {paymentMethod === "cheque" && (
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-1">
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeNumber"
                      >
                        Cheque Number:
                      </label>
                      <input
                        type="text"
                        id="chequeNumber"
                        placeholder="Enter Cheque Number"
                        value={chequeNumber ?? ""}
                        onChange={(e) => setChequeNumber(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeBankName"
                      >
                        Cheque Bank Name:
                      </label>
                      <input
                        type="text"
                        id="chequeBankName"
                        placeholder="Enter Cheque Bank Name"
                        value={chequeBankName ?? ""}
                        onChange={(e) => setChequeBankName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeDate"
                      >
                        Cheque Date:
                      </label>
                      <input
                        type="date"
                        id="chequeDate"
                        required
                        value={chequeDate ?? ""}
                        onChange={(e) => setChequeDate(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 gap-2 md:mt-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="performaBillNo"
                  >
                    Performa Bill No:
                  </label>
                  <input
                    type="text"
                    id="performaBillNo"
                    placeholder="Performa Bill No"
                    value={performaBillNo ?? ""}
                    onChange={(e) => setPerformaBillNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="challanNo"
                  >
                    Challan No:
                  </label>
                  <input
                    type="text"
                    id="challanNo"
                    placeholder="Enter Challan No (Optional)"
                    value={challanNo ?? ""}
                    onChange={(e) => setChallanNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="w-full pt-8 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="commission"
                  >
                    Total Commission:
                  </label>
                  <input
                    type="text"
                    id="commission"
                    placeholder="Enter Total Commision (Optional)"
                    value={commission ?? ""}
                    onChange={(e) => setCommission(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="invisible w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="commission"
                  >
                    Total Commission:
                  </label>
                  <input
                    type="text"
                    id="commission"
                    placeholder="Enter Total Commision (Optional)"
                    value={commission ?? ""}
                    onChange={(e) => setCommission(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                {pmsgyCustomer === "yes" && (
                  <>
                    {/* Electricity Bill Section */}
                    <div className="flex items-center justify-center w-full md:w-1/3 md:px-4">
                      {electricityBillUrl && (
                        <div className="relative">
                          <img
                            src={electricityBillUrl}
                            alt="electricity bill"
                            className="w-20 mt-4 rounded-lg md:w-40"
                          />
                          <a
                            href={electricityBillUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="absolute p-2 text-white bg-black rounded-full bottom-2 right-2"
                          >
                            <FaDownload size={20} />
                          </a>
                        </div>
                      )}
                      {!electricityBillUploading ? (
                        <div className="ml-4 text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleElectricityBillUpload}
                            className="hidden"
                            id="electricity-bill-upload"
                          />
                          <label
                            htmlFor="electricity-bill-upload"
                            className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                          >
                            Electricity Bill
                          </label>
                        </div>
                      ) : (
                        <FaSpinner className="mx-2 animate-spin" size={20} />
                      )}
                    </div>

                    {/* Bank Passbook Section */}
                    <div className="flex items-center justify-center w-full md:w-1/3 md:px-4">
                      {bankPassbookUrl && (
                        <div className="relative">
                          <img
                            src={bankPassbookUrl}
                            alt="bank passbook"
                            className="w-20 mt-4 rounded-lg md:w-40"
                          />
                          <a
                            href={bankPassbookUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="absolute p-2 text-white bg-black rounded-full bottom-2 right-2"
                          >
                            <FaDownload size={20} />
                          </a>
                        </div>
                      )}
                      {!bankPassbookUploading ? (
                        <div className="ml-4 text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleBankPassbookUpload}
                            className="hidden"
                            id="bank-passbook-upload"
                          />
                          <label
                            htmlFor="bank-passbook-upload"
                            className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                          >
                            Bank Passbook
                          </label>
                        </div>
                      ) : (
                        <FaSpinner className="mx-2 animate-spin" size={20} />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-end my-4 ">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="px-4 py-2 text-white bg-blue-900 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                  {updateBtnClicked && (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                  {!updateBtnClicked && "Update Order"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
