import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import LayoutHeader from "../../components/LayoutHeader";
import useCustomersStore from "../../utils/useCustomersStore";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers";
import { FaCopy } from "react-icons/fa";
import { IoNavigate } from "react-icons/io5";
import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs, query } from "firebase/firestore";
import { orderBy } from "lodash";

export default function Messaging() {
  const { querySnapshot } = useCustomersStore();

  const [customerNums, setCustomerNums] = useState([]);
  const [advisorNums, setAdvisorNums] = useState([]);
  const [employeeNums, setEmployeeNums] = useState([]);

  const phoneNumberRegex = /^\d{10}$/;

  const fetchCustomerNums = async () => {
    const nums = [];
    querySnapshot?.forEach((doc) => {
      const phoneNumberMatch = doc.id.match(/\d{10}/);
      if (phoneNumberMatch) {
        nums.push(phoneNumberMatch[0]);
      }
    });

    setCustomerNums(nums);
  };

  const fetchEmployeeNums = async () => {
    const employeesQuery = query(
      collection(db, "employee_list"),
      orderBy("createdAt", "desc")
    );

    const nums = [];
    const querySnapshot = await getDocs(employeesQuery);
    querySnapshot.forEach((doc) => {
      nums.push(doc.data().phone_no);
    });

    const filteredArray = nums.reduce((acc, item) => {
      if (typeof item === "number") {
        acc.push(item.toString());
      } else if (typeof item === "string") {
        const matches = item.match(phoneNumberRegex);
        if (matches) {
          acc.push(...matches);
        }
      }
      return acc;
    }, []);
    setEmployeeNums(filteredArray);
  };

  const fetchAdvisorNums = async () => {
    const advisorsQuery = query(
      collection(db, "advisor_list"),
      orderBy("createdAt", "desc")
    );

    const nums = [];
    const querySnapshot = await getDocs(advisorsQuery);
    querySnapshot.forEach((doc) => {
      nums.push(doc.data().phone_no);
    });

    const filteredArray = nums.reduce((acc, item) => {
      if (typeof item === "number") {
        acc.push(item.toString());
      } else if (typeof item === "string") {
        const matches = item.match(phoneNumberRegex);
        if (matches) {
          acc.push(...matches);
        }
      }
      return acc;
    }, []);
    setAdvisorNums(filteredArray);
  };

  useEffect(() => {
    const getData = async () => {
      // get customer numbers
      await fetchCustomerNums();

      // get advisor numbers
      await fetchAdvisorNums();

      // get employee numbers
      await fetchEmployeeNums();
    };
    getData();

    // eslint-disable-next-line
  }, []);

  const [recipient, setRecipient] = useState("customers");
  const [subject, setSubject] = useState("");

  const [deliveryMethods, setDeliveryMethods] = useState({
    textMessage: false,
    whatsappMessage: false,
    email: false,
  });

  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethods((prevMethods) => ({
      ...prevMethods,
      [method]: !prevMethods[method],
    }));
  };

  const copyToClipboard = () => {
    const textArea = document.getElementById("messageBody");
    textArea.select();
    document.execCommand("copy");

    toast.success("Numbers copied successfully", toastOptions);
  };

  const navigateToFast2SMS = () => {
    const link = "https://www.fast2sms.com/dashboard/dlt";
    window.open(link, "_blank");
  };

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-x-auto overflow-y-auto">
          <LayoutHeader title={"Messaging"} />
          <div className="mx-4">
            <div className="flex flex-col">
              <label htmlFor="to" className="text-sm font-medium">
                To:
              </label>
              <select
                id="to"
                value={recipient}
                onChange={(event) => setRecipient(event.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none "
              >
                <option value="customers">Customers</option>
                <option value="employees">Employees</option>
                <option value="advisors">Advisors</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            {recipient === "custom" && (
              <div className="mt-4">
                <label htmlFor="phoneNumber" className="text-sm font-medium">
                  Phone Number(s):
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none"
                />
              </div>
            )}

            <div className="mt-4">
              <label className="text-sm font-medium">Delivery Method:</label>
              <div className="mt-2 ml-4 space-y-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight text-blue-500"
                    checked={deliveryMethods.textMessage}
                    onChange={() => handleDeliveryMethodChange("textMessage")}
                  />
                  <span className="text-sm">Text Message</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight text-blue-500"
                    checked={deliveryMethods.whatsappMessage}
                    disabled
                    onChange={() =>
                      handleDeliveryMethodChange("whatsappMessage")
                    }
                  />
                  <span className="text-sm">WhatsApp Message</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight text-blue-500"
                    checked={deliveryMethods.email}
                    disabled
                    onChange={() => handleDeliveryMethodChange("email")}
                  />
                  <span className="text-sm">Email</span>
                </label>
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="messageBody" className="text-sm font-medium">
                Message Body:
              </label>
              <input
                id="subject"
                value={subject}
                className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none"
                placeholder="Subject(for email only)"
                onChange={(event) => setSubject(event.target.value)}
              />
              <div className="flex-row justify-end">
                <div className="flex justify-end w-full">
                  <button
                    onClick={copyToClipboard}
                    className="px-4 py-2 mt-2 text-white bg-gray-800 rounded-md focus:outline-none"
                  >
                    <div className="flex items-center">
                      <FaCopy className="mr-2" />
                      <h1 className="text-sm font-semibold">
                        Copy to Clipboard
                      </h1>
                    </div>
                  </button>
                </div>
                <textarea
                  id="messageBody"
                  value={
                    recipient === "customers"
                      ? customerNums.join("\n")
                      : recipient === "advisors"
                      ? advisorNums.join("\n")
                      : recipient === "employees"
                      ? employeeNums.join("\n")
                      : []
                  }
                  rows={10}
                  className="w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none"
                  readOnly
                />
              </div>
            </div>
            <div className="flex justify-end w-full">
              <button
                onClick={navigateToFast2SMS}
                className="px-4 py-2 mt-2 text-white bg-red-600 rounded-md focus:outline-none"
              >
                <div className="flex items-center">
                  <IoNavigate className="mr-2" />
                  <h1 className="text-sm font-semibold">
                    Navigate to Fast2SMS
                  </h1>
                </div>
              </button>
            </div>
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
