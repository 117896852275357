import { FaRegClock, FaSpinner, FaUser } from "react-icons/fa";
import { useCallback, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { parseFirebaseTimestamp } from "../utils/helpers";

function UserCard({
  name,
  email,
  photoUrl,
  lastLogin,
  isVerified,
  admin,
  active,
  uid,
}) {
  const [checked, setChecked] = useState(active);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(async () => {
    setLoading(true);
    const updateUserActiveStatus = doc(db, "users", uid);
    const data = {
      active: !checked,
    };
    await updateDoc(updateUserActiveStatus, data);
    setChecked(!checked);
    setLoading(false);
  }, [checked, uid]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {photoUrl ? (
              <img
                className="w-12 h-12 rounded-full"
                src={photoUrl}
                alt={`${name}'s profile`}
                width={48}
                height={48}
              />
            ) : (
              <div className="flex items-center justify-center w-12 h-12 bg-gray-400 rounded-full">
                <FaUser className="w-6 h-6 text-white" />
              </div>
            )}
          </div>

          <div className="ml-4">
            <h2 className="text-lg font-medium text-gray-800">
              {name}{" "}
              {Boolean(isVerified) && (
                <span className="px-2 py-1 ml-2 text-xs font-semibold text-green-800 bg-green-100 rounded-full">
                  Verified
                </span>
              )}
              {Boolean(admin) && (
                <span className="px-2 py-1 ml-2 text-xs font-semibold text-green-800 bg-green-100 rounded-full">
                  Admin
                </span>
              )}
            </h2>
            <p className="text-sm text-gray-600">{email}</p>
          </div>
        </div>
        {!Boolean(admin) && !loading && (
          <label className="relative items-center cursor-pointer">
            <input
              type="checkbox"
              onChange={handleChange}
              checked={checked}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600">
              {" "}
            </div>
          </label>
        )}
        {loading && <FaSpinner className="mx-2 animate-spin" size={20} />}
      </div>
      {lastLogin && (
        <div className="flex items-center mt-4">
          <FaRegClock className="w-4 h-4 mr-1 text-gray-600" />
          <p className="text-sm text-gray-600">{`Last login: ${parseFirebaseTimestamp(
            lastLogin
          )}`}</p>
        </div>
      )}
    </div>
  );
}

export default UserCard;
