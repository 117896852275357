import React, { useContext, useEffect, useState } from "react";
import UserLayout from "../../components/UserLayout";
import UserProtectedRoute from "../../components/UserProtectedRoute";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { AuthContext } from "../../utils/authContext";
import { PuffLoader } from "react-spinners";
import UserCustomersTable from "../../components/UserCustomersTable";
import LayoutHeader from "../../components/LayoutHeader";

export default function UserCustomers() {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const customersCol = collection(
      db,
      `temp_orders/${currentUser.uid}/orders`
    );

    const unsubscribe = onSnapshot(
      query(customersCol, orderBy("created_at", "desc")),
      (querySnapshot) => {
        try {
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          setCustomers(docs);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    );

    return unsubscribe;
  }, [setCustomers, currentUser.uid]);

  return (
    <UserProtectedRoute>
      <UserLayout>
        <div className="h-full mx-2 overflow-x-auto overflow-y-auto ">
          <LayoutHeader title={"Customers"} />

          {loading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}

          {!loading && <UserCustomersTable customers={customers} />}
        </div>
      </UserLayout>
    </UserProtectedRoute>
  );
}
