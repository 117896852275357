import React, { useEffect, useContext } from "react";
import { AuthContext } from "../utils/authContext";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser, signOut } = useContext(AuthContext);

  useEffect(() => {
    const { email } = currentUser || {};
    const handleSignOut = () => signOut();

    if (!currentUser) {
      navigate("/admin");
    } else if (email !== process.env.REACT_APP_ADMIN_EMAIL) {
      handleSignOut();
    }
  }, [currentUser, navigate, signOut]);

  return (
    currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL && (
      <div>{children}</div>
    )
  );
};

export default ProtectedRoute;
