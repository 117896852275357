import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { collection, getDocs, where, query } from "firebase/firestore";
import LayoutHeader from "../../components/LayoutHeader";
import OrdersTable from "../../components/OrdersTable";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { formatAmountToInr, toastOptions } from "../../utils/helpers";
import { db } from "../../firebase/firebaseConfig";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function AdvisorOrders() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [advisorName, setAdvisorName] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommision, setTotalCommision] = useState(0);

  const [isGoBackClicked, setIsGoBackClicked] = useState(false);

  const handleGoBack = () => {
    setIsGoBackClicked(true);
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const updateOrders = useCallback((newOrders) => {
    setOrders(newOrders);
  }, []);

  const fetchOrdersOfAdvisor = async () => {
    if (id === undefined) return;
    try {
      const ordersCol = collection(db, "orders");
      const ordersQuery = query(ordersCol, where("advisor_code", "==", id));
      const res = await getDocs(ordersQuery);

      updateOrders(res.docs.map((doc) => doc.data()));

      setAdvisorName(res.docs[0].data().advisor_name);

      const totalCost = res.docs.reduce(
        (acc, doc) => acc + (parseInt(doc.data().order_cost) || 0),
        0
      );

      const totalCommission = res.docs.reduce(
        (acc, doc) => acc + (parseInt(doc.data().commission) || 0),
        0
      );

      setTotalOrders(res.docs.length);
      setTotalAmount(totalCost);
      setTotalCommision(totalCommission);
    } catch (error) {
      console.log(error);
      if (error?.code === "resource-exhausted") {
        toast.error("Quota exceeded", toastOptions);
      } else {
        toast.error("Something went wrong", toastOptions);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersOfAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredOrders = useMemo(
    () =>
      orders.filter(
        (order) =>
          order.customer_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (order.customer_phone_no &&
            order.customer_phone_no.includes(searchQuery))
      ),
    [orders, searchQuery]
  );

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-y-auto">
          <LayoutHeader title={`Advisor(${id}) Orders`} />
          <div className="flex items-start mb-4 space-x-4">
            <div className="flex items-center justify-center p-1 m-2 bg-gray-500 rounded-full hover:bg-gray-600 hover:cursor-pointer ">
              <IoIosArrowBack
                size={26}
                className={`text-white transform ${
                  isGoBackClicked ? "rotate-180" : ""
                } transition duration-500 ease-in-out`}
                onClick={handleGoBack}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-bold text-gray-600 ">
                Advisor Name:{" "}
                <span className="ml-4 text-lg">
                  {advisorName === ""
                    ? "Loading..."
                    : advisorName.toUpperCase()}
                </span>
              </div>
              <div className="font-bold text-gray-600">
                Total Sales:{" "}
                <span className="ml-4 text-xl font-semibold text-gray-700">
                  {formatAmountToInr(totalAmount)}
                </span>
              </div>
              <div className="font-bold text-gray-600 ">
                Total Commission:{" "}
                <span className="ml-4 text-xl font-semibold text-gray-700">
                  {formatAmountToInr(totalCommision)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between py-4 mx-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search by Customer's name or Phone number"
              className="px-2 py-2 text-gray-600 border rounded-md w-45 sm:w-96"
            />
            <div className="mr-10">
              <p className="text-sm font-semibold text-gray-600">
                Total Orders:{" "}
                <span className="text-lg font-bold">{totalOrders}</span>
              </p>
            </div>
          </div>
          {loading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}
          <div className="overflow-x-auto">
            {!loading && <OrdersTable orders={filteredOrders} />}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
