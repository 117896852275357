import React, { useState } from "react";
import { parseFirebaseTimestamp } from "../utils/helpers";

const SolarRoofTopLeadsTable = ({ leads }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const truncatedleads = leads.length > 20 ? leads.slice(0, limit) : leads;

  const handleNext = () => {
    setPage(page + 1);
    setLimit(Math.max(1, limit + 20));
  };

  const handlePrevious = () => {
    setPage(Math.max(1, page - 1));
    setLimit(Math.max(1, limit - 20));
  };
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Address
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-bold tracking-wider text-left text-gray-500 uppercase"
            >
              Timestamp
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {truncatedleads.map((lead) => (
            <tr key={lead.id}>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.phone ? lead.phone : "N/A"}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.address}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                {lead.timestamp
                  ? parseFirebaseTimestamp(lead.timestamp)
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between p-4 mt-4">
        <button
          onClick={handlePrevious}
          className={`flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-700 ${
            page === 1 ? "opacity-0" : "opacity-100"
          }`}
          disabled={page === 1}
        >
          Previous
        </button>
        {!(truncatedleads.length >= leads.length) && (
          <button
            onClick={handleNext}
            className="flex items-center p-2 px-4 font-semibold text-white bg-blue-900 border rounded-md hover:bg-blue-700"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

export default SolarRoofTopLeadsTable;
